import React from "react";
import { useNavigate } from "react-router-dom";
import "./FAQs.css";
import { BsArrowLeftShort } from "react-icons/bs";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const navigate = useNavigate();

  // Updated FAQ data based on the provided information
  const faqData = [
    {
      category: "General",
      title: "How do I request a ride with RAJLO?",
      content:
        "Open the RAJLO web app, enter your destination, and request a ride. A nearby driver will be assigned to pick you up.",
    },
    {
      category: "General",
      title: "What types of vehicles does RAJLO offer?",
      content:
        "RAJLO provides a range of vehicles to suit different needs, including standard cars, 7 Seater, and more.",
    },
    {
      category: "Payment",
      title: "Can I pay in cash for RAJLO rides?",
      content: "NO, RAJLO is a cashless digital platform.",
    },
    {
      category: "Safety",
      title: "Are child seats available in RAJLO vehicles?",
      content:
        "Yes, RAJLO offers child seats upon request to ensure the safety of young passengers.",
    },
    {
      category: "Fare",
      title: "How is the fare calculated for RAJLO rides?",
      content:
        "RAJLO fares are calculated based on factors like distance, time, and type of vehicle. You can see the estimated fare before confirming the ride.",
    },
    {
      category: "Ride Options",
      title: "Can I make multiple stops during my RAJLO ride?",
      content:
        "Yes, you can make multiple stops, and additional charges may apply. Please discuss this with your driver.",
    },
    {
      category: "Cancellation",
      title: "What is RAJLO's cancellation policy?",
      content:
        "RAJLO allows free cancellations within a specific timeframe. Late cancellations or no-shows may incur charges.",
    },
    {
      category: "Driver",
      title: "Can I request a specific driver with RAJLO?",
      content:
        "While we can't guarantee specific drivers, you can use the RAJLO app to rate and favorite drivers for future rides.",
    },
    {
      category: "Scheduling",
      title: "Can I schedule a ride in advance with RAJLO?",
      content:
        "Yes, you can schedule rides in advance through the RAJLO app for added convenience.",
    },
    {
      category: "Charges",
      title: "Is there a wait-time before additional charges apply with RAJLO?",
      content:
        "Yes, there might be additional charges for extended wait times. Please check the web app for details.",
    },
    {
      category: "Promotions",
      title:
        "Does RAJLO offer loyalty programs or discounts for frequent riders?",
      content:
        "Yes, RAJLO may have special promotions, discounts, or loyalty programs. Check the web app for current offers.",
    },
    {
      category: "Contact",
      title: "How can I contact my RAJLO driver directly?",
      content:
        "You can contact your driver through the RAJLO app for any specific instructions or updates.",
    },
    {
      category: "Safety",
      title: "What safety measures does RAJLO have in place for riders?",
      content:
        "RAJLO prioritizes safety with features such as driver identification, GPS tracking, and an emergency assistance button in the app.",
    },
    {
      category: "Driver Preference",
      title: "Can I request a female driver with RAJLO?",
      content:
        "While we can't guarantee specific driver demographics, RAJLO is committed to providing a safe and secure environment for all riders.",
    },
    {
      category: "Driver Language",
      title: "What languages do RAJLO drivers speak?",
      content:
        "RAJLO drivers may speak various languages. You can specify language preferences in the app.",
    },
    {
      category: "Ride Options",
      title: "Can I change the destination mid-ride with RAJLO?",
      content:
        "Yes, you can modify your destination during the ride. Additional charges may apply.",
    },
    {
      category: "Driver Ratings",
      title: "How is a RAJLO driver's rating determined?",
      content:
        "RAJLO riders contribute to driver ratings based on factors like courtesy, cleanliness, and overall experience.",
    },
    {
      category: "Support",
      title: "Is there 24/7 customer support for RAJLO?",
      content:
        "Yes, RAJLO provides 24/7 customer support. You can reach us through the app or SUPPORT@RAJLO.COM.",
    },
    {
      category: "Pricing",
      title: "Are there peak-hour surcharges with RAJLO?",
      content:
        "Yes, during busy hours, there might be peak-hour surcharges. Check the app for details.",
    },
    {
      category: "Lost & Found",
      title: "What happens if I forget something in the RAJLO vehicle?",
      content:
        "If you leave something behind, contact RAJLO support immediately. Drivers are instructed to check for lost items.",
    },
    {
      category: "Security",
      title: "Can I see the driver's identification in the RAJLO app?",
      content:
        "Yes, RAJLO provides driver identification details in the app for added security.",
    },
    {
      category: "Updates",
      title: "How often does RAJLO update its app and services?",
      content:
        "RAJLO regularly updates its app and services to enhance the user experience. Make sure to download the latest version for optimal performance.",
    },
  ];

  return (
    <div className="home-screen">
      <div className="faqheader mb-3">
        <button className="faqback-button" onClick={() => navigate(-1)}>
          <BsArrowLeftShort className="faqback-button" />
        </button>
        <h1 className="faqtext mt-3">FAQs</h1>
      </div>
      <div className="faqcards">
        <Accordion>
          {faqData.map((faq, index) => (
            <>
              <div key={index} className="signintext">
                {faq.category}
              </div>
              <Accordion.Item eventKey={String(index)}>
                <Accordion.Header>{faq.title}</Accordion.Header>
                <Accordion.Body>{faq.content}</Accordion.Body>
              </Accordion.Item>
            </>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQs;
