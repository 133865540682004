import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MyRides.css";
import { BsArrowLeftShort, BsFillRecord2Fill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { FaMapMarkerAlt } from "react-icons/fa";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { getRides, getRiderStatus } from "../../services/TripServices";
const MyRides = () => {
  const navigate = useNavigate();
  const [myRides, setMyRides] = useState([]);

  useEffect(() => {
    retrieveRides();
  }, []);

  const retrieveRides = async () => {
    const myRides = await getRides();
    console.log("retrieveRides:", myRides);
    setMyRides(myRides);
  };

  return (
    <div className="home-screen">
      <div className="">
        <div className="editheader">
          <button className="editback-button" onClick={() => navigate(-1)}>
            <BsArrowLeftShort className="editback-button" />
          </button>
          <h1 className="edittext m-2">My Rides</h1>
        </div>

        <div style={{ padding: "20px" }}>
          <Carddata />
        </div>
      </div>
    </div>
  );

  function Carddata() {
    const placeholderImage = "/assets/images/profile.jpg";  // Update this path to your actual placeholder image path
  
    return (
      <div className="">
        {myRides && myRides.length > 0 ? (
          myRides.map((ride, idx) => (
            <Card className="myridecarddiv" key={idx}>
              <Card.Body className="myridebodydiv">
                <div>
                  <div style={{ flex: 1, display: "flex" }}>
                    <div style={{ flex: "0.21" }}>
                      <img
                        src={ride.driverProfile && ride.driverProfile.profilePic ? ride.driverProfile.profilePic : placeholderImage}
                        alt="Driver"
                        className="rideuser-image" // Ensure your CSS styles this appropriately
                      />
                    </div>
                    <div style={{ flex: "0.6" }}>
                      {ride.driverProfile?.name ?? "No driver assigned."}
                      <div className="carName">{getRiderStatus(ride.status)}</div>
                      <div className="carName">{ride.vehicle}</div>
                      <div className="carName">{ride.date}</div>
                    </div>
                    <div style={{ flex: "0.15" }} className="pricestyle">
                      ${parseFloat(ride.fare).toFixed(2)}
                    </div>
                  </div>
                  <div className="myridesource-destination-section mt-3">
                    <div className="myrideconnection-line">
                      <FaMapMarkerAlt
                        style={{ color: "#FF0000" }}
                        className="myridesecondicon"
                      />
                      <div className="myridedotted-line"></div>
                      <BsFillRecord2Fill className="myridefirsticon" />
                    </div>
                    <div>
                      <div className="myrideicondiv mb-4">
                        {ride.destination}
                      </div>
                      <div className="myrideprogress-step__marker"></div>
                      <div className="myridedestination-location-text">
                        {ride.origin}
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))
        ) : (
          <div className="empty-text">No Ride History</div>
        )}
      </div>
    );
  }
  
};

export default MyRides;
