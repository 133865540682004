import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import React, { useEffect } from "react";

import PaymentMethodsInfo from "../../components/PaymentMethodInfo";
import "./AddNewMethod.css";

const AddNewMethod = () => {
  const navigate = useNavigate();

  console.log("Loaded AddNewMethod.");

  useEffect(() => {
    console.log("Component mounted.");
  }, []);

  return (
    <div className="home-screen">
      <div className="paymentheader">
        <button className="headerback-button" onClick={() => navigate(-1)}>
          <BsArrowLeftShort className="headerback-button" />
        </button>
        <h1 className="paymenttext m-2">Payment Method</h1>
      </div>
      <PaymentMethodsInfo />
    </div>
  );
};

export default AddNewMethod;
