import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Notification.css";
import { BsArrowLeftShort } from "react-icons/bs";
import { MdDirectionsCar, MdCancel } from "react-icons/md";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../firebase";

const Notification = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isActive = true; // Flag to check if the component is still mounted

    const userString = localStorage.getItem("userLogin");
    if (!userString) {
      console.error("User inside application is unauthorized");
      return;
    }
    const user = JSON.parse(userString);

    const notificationsQuery = query(
      collection(firestore, "Notifications"),
      where("userId", "==", user.uid),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(
      notificationsQuery,
      (snapshot) => {
        if (!isActive) return; // Ignore the snapshot if component has unmounted
        const fetchedNotifications = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(fetchedNotifications);
        setLoading(false);
      },
      (err) => {
        if (!isActive) return;
        console.error("Failed to fetch notifications:", err);
        setLoading(false);
      }
    );

    return () => {
      isActive = false; // Set flag to false when component unmounts
      unsubscribe(); // Call unsubscribe to clean up the listener
    };
  }, []);

  const getIcon = (type) => {
    switch (type) {
      case "rideNotification":
        return <MdDirectionsCar className="icon" />;
      case "rideCancellation":
        return <MdCancel className="icon" />;
      default:
        return <MdDirectionsCar className="icon" />; // Default to a general icon if type is unrecognized
    }
  };

  return (
    <div className="home-screen">
      <div className="">
        <div className="notiheader">
          <button className="back-button" onClick={() => navigate(-1)}>
            <BsArrowLeftShort className="headerback-button" />
          </button>
          <h1 className="methodtext m-2">Notifications</h1>
        </div>
        {loading ? (
          <div className="loading-overlay">
            <div className="loader"></div>
            <p>Loading notifications...</p>
          </div>
        ) : notifications.length === 0 ? (
          <div className="empty-text">
            <p>No notifications exist.</p>
          </div>
        ) : (
          notifications.map((notification) => (
            <div
              key={notification.id}
              className="notilisting mt-4"
              style={{ display: "flex" }}
            >
              <div className="noticircle-icon">
                {getIcon(notification.type)}
              </div>
              <div className="notiheadingtext" style={{ flex: 0.7 }}>
                {notification.title}
                <div className="notisecondtext">{notification.message}</div>
              </div>
              <div
                className="notitimetext"
                style={{ flex: 0.3, textAlign: "right" }}
              >
                {notification.timestamp
                  ? new Date(
                      notification.timestamp.seconds * 1000
                    ).toLocaleString()
                  : "Unknown time"}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Notification;
