import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./EditProfile.css";
import { FaCamera } from "react-icons/fa";
import Sheet from "react-modal-sheet";
import { BsArrowLeftShort } from "react-icons/bs";
import { FaRegImage } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import global from "../../global";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { fetchUserData } from "../../services/userService";
const EditProfile = () => {
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({});
  const [fname, setFname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const fileInputRef = useRef(null);
  const placeholderImage = "/assets/images/profile.jpg"; // Update this path to your actual placeholder image path
  const defaultAvatarImage = "https://www.pngitem.com/pimgs/m/256-2560275_avatar-icon-red-png-clipart-png-download-red.png";

  useEffect(() => {
    setLoading(true);

    fetchUserData().then((userData) => {
      setFname(userData.name || "");
      setEmail(userData.email || "");
      setPhone(userData.phone || "");
      setUserData(userData);

      setLoading(false);
    });
  }, []);

  const handleFileUpload = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    const result = await updateUserProfilePicture(file);
    const userData = await fetchUserData();
    setUserData(userData);
    setUploadMessage(result.message);
    setUploading(false);
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
    setOpen(false);
  };

  const handleRemoveClick = async () => {
    const uid = userData.id;
    const userRef = doc(firestore, "User", uid);
    const userdata = { image: defaultAvatarImage };
    await updateDoc(userRef, userdata);
    const userDataUpdated = await fetchUserData();
    setUserData(userDataUpdated);
    setOpen(false);
  };

  const setSheetOpen = (open) => {
    setOpen(open);
  };
  // const camerabutton = () => {
  //   chooseOption()
  //   // navigate('/editprofile');
  // };

  const updateProfileInfo = async () => {
    try {
      const userRef = doc(firestore, "User", userData.id);
      const userdata = {
        name: fname,
        email,
        phone,
      };
      await updateDoc(userRef, userdata);
      navigate("/home");
    } catch (error) {
      alert("Error updating profile info.");
      console.log("error updating profile info: ", error);
    }
  };

  const updateUserProfilePicture = async (file) => {
    try {
      console.log("uploading profile image: ", userData);
      const uid = userData.id || "1";
      const storage = getStorage();
      const storageRef = ref(storage, "profile-image/" + uid);

      // 'file' comes from the Blob or File API
      const snapshot = await uploadBytes(storageRef, file);
      console.log("Uploaded profile image:", snapshot);

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);
      console.log("Got download url:", snapshot);

      // Update the user's profile with the new profile picture URL
      const userRef = doc(firestore, "User", uid);
      const userdata = { image: downloadURL };
      await updateDoc(userRef, userdata);

      return {
        success: true,
        message: "Profile picture updated successfully.",
      };
    } catch (error) {
      console.error("Error updating profile picture:", error);
      return {
        success: false,
        message: "An error occurred while updating the profile picture.",
      };
    }
  };

  return (
    <div className="home-screen">
      {/* <MapComponent /> */}

      <div className="">
        <div className="editheader">
          <button className="editback-button" onClick={() => navigate(-1)}>
            <BsArrowLeftShort className="editback-button" />
          </button>
          <h1 className="edittext m-2">Edit Profile</h1>
        </div>

        {isLoading ? (
          <div className="loading-overlay">
            <div className="loader"></div>
            <p>Loading notifications...</p>
          </div>
        ) : (
          <div className=" mt-4" style={{ alignItems: "center", flex: "0.4" }}>
            <div style={{ display: "flex", marginTop: 20 }}>
              <div style={{ flex: "1" }}>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                {uploading && <p>Uploading...</p>}
                {uploadMessage && <p>{uploadMessage}</p>}
                <div className="cameracircle-icon">
                  <FaCamera
                    className="cameracircle"
                    onClick={() => setOpen(true)}
                  />
                </div>
                <img
                  src={userData.image || placeholderImage}
                  alt="Profile"
                  className="edituser-image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = placeholderImage;
                  }} // Fallback in case the image still fails to load
                />
              </div>
            </div>
          </div>
        )}

        <div style={{ padding: "20px" }} onClick={() => setOpen(false)}>
          {fullName()}
          {emailfun()}
          {phoneNumber()}
          {/* {passwordfun()} */}
        </div>
      </div>
      <div className="editbuttondiv">{saveButton()}</div>
      <ChooseOption
        isOpen={isOpen}
        setSheetOpen={setSheetOpen}
        handleIconClick={handleIconClick}
        handleRemoveClick={handleRemoveClick}
        userData={userData}
        defaultAvatarImage={defaultAvatarImage}
        style={{ height: "100px" }}
      />
    </div>
  );

  function fullName() {
    return (
      <div className="editinput-group mb-4">
        <div className="editlabel-container">
          <label className="editlabeltext">Full Name</label>
        </div>
        <div className="editnameInputContainer">
          <input
            style={{ color: "black" }}
            type="text"
            value={fname}
            onChange={(e) => setFname(e.target.value)}
            placeholder="Enter your first name..."
            className="editnameInputStyle"
          />
        </div>
      </div>
    );
  }

  function emailfun() {
    return (
      <div className="editinput-group mb-4">
        <div className="editlabel-container">
          <label className="editlabeltext">Email Address</label>
        </div>
        <div className="editnameInputContainer">
          <input
            style={{ color: "black" }}
            type="tel"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email..."
            className="editnameInputStyle"
          />
        </div>
      </div>
    );
  }

  function phoneNumber() {
    return (
      <div className="editinput-group mb-4">
        <div className="editlabel-container">
          <label className="editlabeltext">Phone Number</label>
        </div>
        <div className="editnameInputContainer">
          <input
            style={{ color: "black" }}
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter your phone number..."
            className="editnameInputStyle"
            disabled
          />
        </div>
      </div>
    );
  }

  function passwordfun() {
    return (
      <div className="editinput-group mb-4">
        <div className="editlabel-container">
          <label className="editlabeltext">Password</label>
        </div>
        <div className="editnameInputContainer">
          <input
            style={{ color: "black" }}
            type="tel"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter here..."
            className="editnameInputStyle"
          />
        </div>
      </div>
    );
  }
  function saveButton() {
    return (
      <button
        className="editcontinue-button"
        onClick={() => updateProfileInfo()}
      >
        Save
      </button>
    );
  }
};

const ChooseOption = ({ userData,
  defaultAvatarImage,
  isOpen,
  setSheetOpen,
  handleIconClick,
  handleRemoveClick }) => {
  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setSheetOpen(false)}
      snapPoints={[230, 400, 100, 0]}
      disableScrollLocking={true}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content
          disableDrag={true}
          className={isOpen ? "chooseOption-sheet" : ""}
        >
          <Sheet.Scroller>
            <div className="chooseOption-container">
              <div className="optionheader mb-4">Choose Option</div>
              {/* <div className="mb-3" style={{display:'flex',marginInline: '20px'}}>
            <FaCamera className="cameraIcon mt-1" />
            <div className="cameratext">
            Use Camera
            </div>
          </div> */}
              <div
                className="mb-3 pointer"
                style={{ display: "flex", marginInline: "20px" }}
                onClick={handleIconClick}
              >
                <FaRegImage className="cameraIcon mt-1" />
                <div className="cameratext">Update Profile Photo</div>
              </div>
              {
                (!userData.image || userData.image !== defaultAvatarImage) &&
                <div
                  className="pointer"
                  style={{ display: "flex", marginInline: "16px" }}
                  onClick={handleRemoveClick}
                >
                  <MdDelete className="deleteIcon mt-1" />
                  <div className="cameratext">Remove Profile Photo</div>
                </div>
              }
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default EditProfile;
