import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OnboardingScreen from "./screens/onboarding/OnboardingScreen";
import HomeScreen from "./screens/home/HomeScreen";
import LoginScreen from "./screens/auth/LoginScreen";
import RegisterScreen from "./screens/auth/RegisterScreen";
import VerificationScreen from "./screens/auth/VerificationScreen";
import "bootstrap/dist/css/bootstrap.css";
import SelectVehicle from "./screens/home/SelectVehicle";
import SearchingForDrivers from "./screens/home/SearchingForDrivers";
import Payment from "./screens/home/Payment";
import DriverDetail from "./screens/home/DriverDetail";
import EndRide from "./screens/home/EndRide";
import TripRoute from "./screens/home/TripRoute";
import PaymentMethod from "./screens/home/PaymentMethod";
import AddNewMethod from "./screens/home/AddNewMethod";
import Notification from "./screens/home/Notification";
import EditProfile from "./screens/home/EditProfile";
import MyRides from "./screens/home/MyRides";
import ContactUs from "./screens/home/ContactUs";
import FAQs from "./screens/home/FAQs";
import BookRide from "./screens/home/BookRide";
import InternetStatus from "./components/InternetStatus";

import {loadStripe} from '@stripe/stripe-js/pure';
// import { loadStripe } from "@stripe/stripe-js";
import global from "./global";
import { Elements } from "@stripe/react-stripe-js";
import PrivacyPolicy from "./screens/legalese/PrivacyPolicy";
import TermsOfUse from "./screens/legalese/TermsOfUse"

// const stripePromise = loadStripe(global.stripePublicKey).finally((message) =>
//   console.log(`Stripe Loaded`)
// );

const stripePromise = (async () => {
  try {
    console.log("loading Stripe...");
    return await loadStripe(global.stripePublicKey).finally(()=>{    
      console.log("stripe loaded");
  })
  } catch (err) {
    console.error("stripePromise", err);
    window.location.reload();
  }
})();

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<OnboardingScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/verify" element={<VerificationScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/bookride" element={<BookRide />} />
          <Route path="/selectvehicle" element={<SelectVehicle />} />
          <Route path="/payment" element={<Payment />} />
          <Route
            path="/searchingfordrivers"
            element={<SearchingForDrivers />}
          />
          <Route path="/driverdetail" element={<DriverDetail />} />
          <Route path="/endride" element={<EndRide />} />
          <Route path="/triproute" element={<TripRoute />} />
          <Route path="/paymentmethod" element={<PaymentMethod />} />
          <Route
            path="/addnewmethod"
            element={
              <Elements stripe={stripePromise}>
                <AddNewMethod />
              </Elements>
            }
          />
          <Route path="/notification" element={<Notification />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/myrides" element={<MyRides />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="*" element={<OnboardingScreen />} />
        </Routes>
      </div>

      {/* Used for recaptcha */}
      <div id="recaptcha-container"></div>

      <InternetStatus />
    </Router>
  );
}

export default App;
