const global = {};
global.user = {};
global.env = "prod";
global.googleMapsKey = "AIzaSyAkFxK3CMpP7OKZwTj05UknVH8S3G0HgYY";
global.stripePublicKey="pk_test_zbLW9VcEErbXFI0CoBieXGo3";
global.firebaseFunctions = {
    dev: {
        "createRide": "http://127.0.0.1:5002/rajlo-dev/us-central1/createRide",
        "addCard": "http://127.0.0.1:5002/rajlo-dev/us-central1/addCard",
        "cancelRide": "http://127.0.0.1:5002/rajlo-dev/us-central1/cancelRide",
        "deleteCard": "http://127.0.0.1:5002/rajlo-dev/us-central1/deleteCard"
    },
    prod: {
        "createRide": "https://createride-jvj2g4gh7q-uc.a.run.app",
        "addCard": "https://addcard-jvj2g4gh7q-uc.a.run.app",
        "cancelRide": "https://cancelride-jvj2g4gh7q-uc.a.run.app",
        "deleteCard": "https://deletecard-jvj2g4gh7q-uc.a.run.app"
    },
}
export default global;
