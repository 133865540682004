import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MapComponent from "../../components/MapComponent";
import "./TripRoute.css";
import {
  BsArrowLeftShort,
  BsFillRecord2Fill,
  BsFillChatLeftTextFill,
} from "react-icons/bs";
import {
  FaStar,
  FaBars,
  FaSearch,
  FaBullseye,
  FaMapMarker,
  FaMapPin,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { IoIosArrowUp, IoIosCall, IoIosArrowDown } from "react-icons/io";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";

const driverDetail = {
  id: "1",
  name: "Cameron Williamson",
  color: "White",
  vehicle: "Nissan Altima",
  licensePlate: "CTZ 1857",
};

const destinations = [
  {
    name: "Bailey Drive, Fredericton",
    address: "9 Bailey Drive, Fredericton, NB E3B 5A3",
    destinationType: "departure",
  },
  {
    name: "Island Pkwy, Kamloops",
    address: "225 Belleville St, Victoria, BC V8V 1X1",
    destinationType: "arrival",
  },
];

const paymentmethods = [
  {
    id: "1",
    paymentIcon: "/assets/images/paymentMethods/wallet.png",
    paymentType: "card",
    cardNumber: "**** **** **56 7896",
    cardExpiry: "04/25",
  },
];

const TripRoute = () => {
  const navigate = useNavigate();
  const savedLat = localStorage.getItem("currentLat");
  const savedLng = localStorage.getItem("currentLng");

  // Convert them back to numbers since localStorage stores everything as strings
  const initialLat = savedLat ? parseFloat(savedLat) : null;
  const initialLng = savedLng ? parseFloat(savedLng) : null;

  return (
    <div className="home-screen">
      <MapComponent lat={initialLat} lng={initialLng} />

      <div className="map-container">
        <div className="map-overlay">
          <div className="tripheader">
            <button className="tripback-button" onClick={() => navigate(-1)}>
              <BsArrowLeftShort className="tripback-button" />
            </button>
          </div>

          <TripRouteSection />
        </div>
      </div>
    </div>
  );
};

const TripRouteSection = () => {
  const navigate = useNavigate();

  const [driverDetail, setDriverDetail] = useState({});
  const { state } = useLocation();
  const { ride } = state;

  useEffect(() => {
    getDriverInfo(ride.driver);
  }, [ride]);

  const getDriverInfo = async (driverId) => {
    console.log("gettig driver info: ", driverId, ride);
    const driverDocRef = doc(firestore, "User", driverId);
    const driverDoc = await getDoc(driverDocRef);

    if (driverDoc.empty) {
      throw new Error("There is no information for this driver");
    }
    const driver = driverDoc.data();
    const driverDetail = {
      id: driverId,
      name: driver.name,
      // color: driver.vehicle.color || "White",
      // vehicle: driver.vehicle.make + " " + driver.vehicle.model,
      // licensePlate: driver.vehicle.vehicleNumber,
      image: driver.image,
    };
    console.log(driverDetail);

    setDriverDetail(driverDetail);
  };

  const destinations = [
    {
      name: "",
      address: ride.origin.address,
      destinationType: "departure",
    },
    {
      name: "",
      address: ride.destination.address,
      destinationType: "arrival",
    },
  ];

  const returnHome = () => {
    navigate("/home");
  };
  // const recentDestinations = [
  //   {
  //     name: "Bailey Drive, Fredericton",
  //     address: "9 Bailey Drive, Fredericton, NB E3B 5A3",
  //   },
  //   {
  //     name: "Island Pkwy, Kamloops",
  //     address: "225 Belleville St, Victoria, BC V8V 1X1",
  //   },
  // ];

  // Add any logic or state related to recent destinations here
  return (
    <div className="recent-destinations-container">
      <div className="recent-destinations-bar"></div>
      <div className="">
        <div
          style={{
            padding: "10px",
            marginTop: "20px" /* Adjust as needed */,
          }}
        >
          <img
            // src={"/assets/images/users/user2.png"} // Adjust the path as needed
            src={driverDetail.image}
            alt="Driver detail" // Adjust the path as needed
            className="enddriver-image" // Adjust height as needed
          />

          <div className="tripdriver-name">{driverDetail.name}</div>
          <div className="trip-description-container">
            <div className="trip-description mb-4">
              <div className="trip-description-item">
                <div className="trip-description-item-subtitle">
                  You Reached Destination
                </div>
              </div>
            </div>
          </div>
          <div className="triptext mb-2">Trip Route</div>
          <div className="tripsource-destination-section mb-3">
            <div>
              <div className="tripconnection-line">
                <BsFillRecord2Fill className="tripfirsticon" />
                <div className="tripdotted-line"></div>
                <FaMapMarkerAlt
                  style={{ color: "#FF0000" }}
                  className="tripsecondicon"
                />
              </div>
            </div>
            <div>
              <div className="tripicondiv mb-3">{destinations[0].address}</div>
              <div className="triphrline mb-3"></div>

              <div className="tripprogress-step__marker"></div>
              <div className="tripcurrent-location-text">
                {destinations[1].address}
              </div>
            </div>
          </div>
        </div>
        <div className="triphrline mb-3">
          <button onClick={returnHome} className="buttonStyleFw">
            Ride Complete
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripRoute;
