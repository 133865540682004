import React from 'react';
import "./legalese.css"
import { Link } from 'react-router-dom';

const TermsOfUse = () => {
    return (
        <div className="legalese">
            <div className='float-right'>
                <Link to="/home">Return Home</Link>
            </div>

            <h1>Terms and Conditions</h1>
            
            <p>Last Updated: July 31, 2024</p>

            <h2>1. Introduction</h2>

            <p>Welcome to Rajlo.com, a ride-sharing platform connecting users with drivers for convenient transportation services. By accessing or using the Rajlo mobile application ("the App"), you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before using the App.</p>

            <h2>2. Definitions</h2>

            <p>
                <ul>
                    <li>User: Refers to individuals who access or use the Rajlo App to request transportation services.</li>
                    <li>Driver: Refers to individuals who provide transportation services to Users through the <u>rajlo.com</u> platform.</li>
                    <li>Service: Refers to the transportation services facilitated by the Rajlo App.</li>
                    <li>Stripe: Refers to the payment processing service provided by Stripe, Inc.</li>
                    <li>NCB Bank: Refers to National Commercial Bank Jamaica Limited, the bank utilized for payment processing.</li>
                </ul>
            </p>

            <h2>3. User Accounts</h2>

            <p>
                <ul>
                    <li>Users must create an account to access and use the Rajlo Ja App.</li>
                    <li>Users are responsible for maintaining the confidentiality of their account credentials and are liable for all activities that occur under their account.</li>
                </ul>
            </p>

            <h2>4. Services Provided</h2>

            <p>
                <ul>
                    <li>Rajlo Ja provides a platform for Users to connect with Drivers for transportation services.</li>
                    <li>Users can request rides through the App, and Drivers can accept or decline ride requests based on their availability.</li>
                </ul>
            </p>

            <h2>5. User Conduct</h2>

            <p>
                <ul>
                    <li>Users must use the Rajlo Ja App responsibly and abide by all applicable laws and regulations.</li>
                    <li>Harassment, discrimination, or any form of abusive behavior towards Drivers or other Users is strictly prohibited.</li>
                </ul>
            </p>

            <h2>6. Driver Conduct</h2>

            <p>
                <ul>
                    <li>Drivers must adhere to all traffic laws and regulations while providing transportation services.</li>
                    <li>Drivers are expected to maintain professionalism and provide a safe and comfortable ride experience for Users.</li>
                </ul>
            </p>

            <h2>7. Payment</h2>

            <p>
                <ul>
                    <li>Payment for rides will be processed through Stripe, utilizing NCB Bank as the payment processor.</li>
                    <li>Users will be charged for rides based on the distance traveled and any applicable fees.</li>
                    <li>Users are responsible for ensuring that their payment information is accurate and up-to-date.</li>
                </ul>
            </p>

            <h2>8. Cancellation Policy</h2>

            <p>
                <ul>
                    <li>Users may cancel ride requests, but may be subject to cancellation fees if the cancellation occurs after a certain time period.</li>
                    <li>Drivers may also cancel ride requests, but excessive cancellations may result in penalties.</li>
                </ul>
            </p>

            <h2>9. Liability</h2>

            <p>
                <ul>
                    <li>Rajlo.com and its operators are not liable for any damages, injuries, or losses incurred during rides arranged through the App.</li>
                    <li>Users and Drivers are responsible for their own safety and should take necessary precautions during transportation.</li>
                </ul>
            </p>

            <h2>10. Intellectual Property</h2>

            <p>
                <ul>
                    <li>All intellectual property rights related to the Rajlo.com platform are owned by Rajlo Jamaica Ltd. and its licensors.</li>
                    <li>Users and Drivers are prohibited from reproducing or using any content from the App without prior authorization.</li>
                </ul>
            </p>

            <h2>11. Intellectual Property</h2>

            <p>
                <ul>
                    <li>The Rajlo.com Privacy Policy governs the collection, use, and protection of user data. By using the App, you consent to the terms outlined in the Privacy Policy.</li>
                </ul>
            </p>

            <h2>12. Termination</h2>

            <p>
                <ul>
                    <li>Rajlo.com reserves the right to terminate or suspend User accounts or access to the App at any time, for any reason, without prior notice.</li>
                </ul>
            </p>

            <h2>13. Changes to Terms and Conditions</h2>

            <p>
                <ul>
                    <li>Rajlo.com may update or modify these Terms from time to time without prior notice. Continued use of the App after such changes constitutes acceptance of the updated Terms.</li>
                </ul>
            </p>

            <h2>14. Governing Law</h2>

            <p>
                <ul>
                    <li>These Terms and any disputes arising from them are governed by the laws of Jamaica. Any legal action or proceeding relating to these Terms shall be brought exclusively in the courts of Jamaica.</li>
                </ul>
            </p>

            <h2>15. Contact Information</h2>

            <p>
                <ul>
                    <li>For questions or concerns regarding these Terms or the <u>rajlo.com</u> platform, please contact us at <a href="mailto:contact@rajlo.com">contact@rajlo.com</a>.</li>
                </ul>
            </p>
        </div>
    )
}

export default TermsOfUse