import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import safeParseJSON from "../services/jsonParser";
import global from '../global';

function convertObjectToArray(obj) {
  // Create an empty array to hold the transformed objects
  const result = [];

  // Loop over each key in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the key is an actual property of the object
      const item = obj[key]; // Get the value associated with the key
      // Create a new object that includes the id field and all other properties
      result.push({
        id: key,
        expYear: item.expYear,
        cardBrand: item.cardBrand,
        last4: item.last4,
        expMonth: item.expMonth,
        cardHolderName: item.cardHolderName,
      });
    }
  }

  return result;
}

const fetchPaymentMethods = async () => {
  const firestore = getFirestore();
  const riderJSON = localStorage.getItem("userLogin"); // Assuming user info is stored here
  const rider = riderJSON ? JSON.parse(riderJSON) : null;

  if (rider && rider.uid) {
    const docRef = doc(firestore, "User", rider.uid);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();

        // console.log("userData.paymentMethods: ", userData.paymentMethods);

        return userData.paymentMethods;
      } else {
        console.log("No such document!");
        return [];
      }
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      return [];
    }
  }
};

export const createPaymentMethod = async (paymentMethod) => {
  const userJSON = localStorage.getItem("userLogin");
  const rider = safeParseJSON(userJSON);
  let postData = { rider, paymentMethod };


  try {
    const addCardUrl = global.env == "prod"? global.firebaseFunctions.prod.addCard : global.firebaseFunctions.dev.addCard;
    const response = await fetch(addCardUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData)
    });

    const data = await response.json(); // Always parse the JSON to check for error or success message

    if (!response.ok) {
      // Throw an error with the message from the server or a generic message if none is provided
      throw new Error(data.message || 'Network response was not ok');
    }

    console.log('Payment method added successfully:', data);
    return data;
  } catch (error) {
    console.error('Failed to add payment method:', error);
    // Rethrow the error to be caught by the caller of the function
    throw new Error(error.message || 'Failed to add payment method');
  }
};


export const deleteCard = async (paymentMethod) => {
  const userJSON = localStorage.getItem("userLogin");
  const rider = safeParseJSON(userJSON);
  let postData = { rider, paymentMethod };

  try {
    const deleteCardUrl = global.env === "prod"? global.firebaseFunctions.prod.deleteCard : global.firebaseFunctions.dev.deleteCard;
    const response = await fetch(deleteCardUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData)
    });

    const data = await response.json(); // Always parse the JSON to check for error or success message

    if (!response.ok) {
      // Throw an error with the message from the server or a generic message if none is provided
      throw new Error(data.message || 'Network response was not ok');
    }

    console.log('Payment method delete successfully:', data);
    return data;
  } catch (error) {
    console.error('Failed to delete payment method:', error);
    // Rethrow the error to be caught by the caller of the function
    throw new Error(error.message || 'Failed to add payment method');
  }
};


export default fetchPaymentMethods;
