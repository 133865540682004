import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FiPlusCircle } from "react-icons/fi";
import { deleteCard } from "../services/paymentService";

const getPaymentIcon = (cardBrand) => {
  let cardLogo = "";
  switch (cardBrand) {
    case "visa":
      cardLogo = "/assets/images/paymentMethods/visa.png";
      break;
    case "mastercard":
      cardLogo = "/assets/images/paymentMethods/masterCard.png";
      break;
    case "wallet":
      cardLogo = "/assets/images/paymentMethods/wallet.png";
      break;
    default:
      cardLogo = "/assets/images/paymentMethods/wallet.png";
      break;
  }

  return cardLogo;
};

const PaymentMethodListItem = ({ paymentMethodItem, index, maxWidth, reloadCards }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deleteCard(paymentMethodItem);
      console.log(response.data); // Handle response data as needed
      setIsLoading(false);

      reloadCards();

    } catch (error) {
      console.error("Error deleting payment method:", error);
      alert("Failed to delete payment method.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      key={index}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between", // Adjusted for space between items
        padding: "8px",
        border: "1px solid #E6E6E6",
        marginBottom: "8px",
        borderRadius: "6px",
        backgroundColor: "transparent",
        color: "black",
        maxWidth: maxWidth,
        margin: "8px auto",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {" "}
        {/* Added container for left side */}
        <img
          src={getPaymentIcon(paymentMethodItem.brand)}
          alt="Payment Icon"
          style={{ width: "40px", height: "40px", objectFit: "contain" }}
        />
        <div style={{ marginLeft: "8px", flex: 1 }}>
          <div
            style={{
              fontWeight: "600",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textAlign: "left",
              textOverflow: "ellipsis",
            }}
          >
            {"**** **** **** " + paymentMethodItem.last4}
          </div>
          <div
            style={{
              fontWeight: "200",
              whiteSpace: "nowrap",
              fontSize: "14px",
              overflow: "hidden",
              textAlign: "left",
              textOverflow: "ellipsis",
            }}
          >
            Expires {paymentMethodItem.expMonth}/{paymentMethodItem.expYear}
          </div>
        </div>
      </div>
      <button
        onClick={handleDelete}
        disabled={isLoading}
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          color: "#fb0101",
        }}
      >
        {isLoading ? (
          <div className="inline-loader">
            <div className="loader-btn"></div>
          </div>
        ) : (
          "Delete"
        )}
      </button>
    </div>
  );
};

export default PaymentMethodListItem;
