import {
  CardNumberElement,
  // PaymentElement,
  CardElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./PaymentMethodInfo.css";

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { createPaymentMethod } from "./../services/paymentService";

const PaymentMethodsInfo = ({ handleSavePayment }) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },

        iconColor: "#666EE8",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const navigateToPaymentmethod = () => {
    navigate("/paymentmethod");
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    console.log(`stripe and elements: `, stripe, elements);
    if (!stripe || !elements) {
      console.log(" Stripe.js has not yet loaded.");
      setIsLoading(false);
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: cardHolderName, // Include the cardholder's name
      },
    });

    if (error) {
      console.log("[error]", error);
      setErrorMessage(error.message);
      setIsLoading(false);
    } else {
      console.log("PaymentMethod:", paymentMethod);

      // send the paymentMethod details to your Firebase Backend here
      try {
        const response = await createPaymentMethod(paymentMethod);
        if (response.error) {
          throw new Error(response.message);
        }
        setSuccessMessage("Payment method added successfully.");
        // navigateToPaymentmethod();
        navigateBack();
      } catch (error) {
        console.error("Error adding payment method:", error);
        setSuccessMessage("");
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCardHolderNameChange = (event) => {
    setCardHolderName(event.target.value);
  };

  return (
    <div style={{ marginTop: "16px", margin: "30px" }}>
      {stripe ? ( // Check if stripe is loaded
          <form onSubmit={handleSubmit} className="card-form">
            <div className="textcard">
              <div className="textlabel">CARDHOLDER'S NAME</div>
              <input
                type="text"
                name="cardHolderName"
                value={cardHolderName}
                onChange={handleCardHolderNameChange}
                placeholder="Full Name"
                className="cardnumberInputStyle"
              />
            </div>

            <div className="textcard">
              <div className="textlabel">CARD NUMBER </div>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            <div className="submit-btn">
              <button
                type="submit"
                disabled={!stripe || isLoading}
                className="addbuttonStyle"
                style={{ textDecoration: "none" }}
              >
                {isLoading ? (
                  <div className="inline-loader">
                    <div className="loader-btn"></div>
                  </div>
                ) : (
                  "Save Payment Method"
                )}
              </button>
            </div>
          </form>
      ) : (
        <p>Payment Processor is loading...</p>
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
    </div>
  );
};

export default PaymentMethodsInfo;
