import axios from "axios";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  serverTimestamp,
  updateDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { auth, firestore } from "../firebase";
import global from "../global";

export const getDistanceAndDurationFromGoogleMaps = (origin, destination) => {
  return new Promise((resolve, reject) => {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === "OK") {
          let results = response.rows[0].elements;
          let distance = results[0].distance.text;
          let duration = results[0].duration.text;
          resolve({
            success: true,
            data: { distance, duration },
            errorMessage: null,
          });
        } else {
          reject({
            success: false,
            data: null,
            errorMessage: "Failed to fetch distance and duration",
          });
        }
      }
    );
  });
};

// Fare calculation function
const calculateFareRouteTaxi = (distance) => {
  const baseRate = 113;
  const ratePerKm = 7;
  let calculatedFare = baseRate + distance * ratePerKm;
  calculatedFare = Math.round(calculatedFare / 10) * 10; // Round to nearest $10
  return calculatedFare;
};

const calculateFareRajloX = (distance, duration) => {
  const baseRate = 350;
  const ratePerKm = 60;
  const ratePerMin = 5;

  let calculatedFare = baseRate + distance * ratePerKm + duration * ratePerMin;
  calculatedFare = Math.round(calculatedFare / 10) * 10; // Round to nearest $10
  return calculatedFare;
};

// Service function that integrates distance fetching and fare calculation
export const calculateFareForTrip = async (origin, destination) => {
  const distance = await getDistanceAndDurationFromGoogleMaps(
    origin,
    destination
  );

  if (!distance.success) {
    return distance;
  }

  const fare = calculateFareRouteTaxi(
    Number(distance.data.distance.split(" ")[0])
  );
  console.log(
    `The calculated fare for a trip from ${origin} to ${destination} using a Route Taxi is: $${fare}`
  );

  const fareRajloX = calculateFareRajloX(
    Number(distance.data.distance.split(" ")[0]),
    Number(distance.data.duration.split(" ")[0])
  );
  console.log(
    `The calculated fare for a trip from ${origin} to ${destination} using a Route X is: $${fareRajloX}`
  );

  return {
    success: true,
    data: {
      fare: fare,
      fares: {
        routeTaxi: fare,
        rajloX: fareRajloX,
      },
      distance: Number(distance.data.distance.split(" ")[0]),
    },
    errorMessage: null,
  };
};

export const createRide = async (rideData) => {
  try {
    let rideResponse = await fetchCreateRide(rideData);

    return rideResponse;
  } catch (error) {
    console.error("Error adding ride: ", error);
    return { success: false, data: null, errorMessage: "Error creating ride." };
  }
};

const fetchCreateRide = async (rideData) => {
  try {
    const requestBody = JSON.stringify(rideData);

    console.log("fetchCreateRide requestBody: ", requestBody);
    const createRideUrl =
      global.env == "prod"
        ? global.firebaseFunctions.prod.createRide
        : global.firebaseFunctions.dev.createRide;

    const response = await fetch(createRideUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: requestBody,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Ride created successfully:", data);
    return data;
  } catch (error) {
    console.error("Failed to create ride:", error);

    throw new Error("Failed to create ride");
  }
};

export const cancelRide = async (tripId) => {
  try {
    const requestBody = JSON.stringify({ rideId: tripId });

    console.log("fetchCreateRide requestBody: ", requestBody);
    const cancelRideUrl =
    global.env == "prod"
      ? global.firebaseFunctions.prod.cancelRide
      : global.firebaseFunctions.dev.cancelRide;
    const response = await fetch(cancelRideUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: requestBody,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Ride cancelled successfully:", data);
    return data;
  } catch (error) {
    console.error("Failed to cancel ride:", error);

    throw new Error("Failed to cancel ride");
  }
};

export const getRiderStatus = (status) => {
  let uiStatus = "";

  switch (status) {
    case "pending":
      uiStatus = "Pending";
      break;
    case "pendingPickup":
      uiStatus = "Pending Pickup";
      break;
    case "riderCancelled":
      uiStatus = "Cancelled Trip";
      break;
    default:
      break;
  }

  return uiStatus;
};

export const getRides = async () => {
  const user = JSON.parse(localStorage.getItem("userLogin"));
  console.log("retrieveRides user: ", user);

  const snapShot = query(
    collection(firestore, "Rides"),
    where("rider", "==", user.uid)
  );

  const rides = await getDocs(snapShot);

  // Map the rides to a structured format
  const myRides = rides.docs.map((ride) => {
    const date = ride.get("createdAt");
    const formattedDate = date ? new Date(date.seconds * 1000).toString() : "Unknown";
    const vehicle = ride.get("vehicle_type");
    const destination = ride.get("destination.address");
    const origin = ride.get("origin.address");
    const fare = ride.get("fare");
    const status = ride.get("status");
    const driverProfile = ride.get("driverProfile") ?? null;
    const { id } = ride;

    const rideObject = {
      date: formattedDate,
      vehicle,
      destination,
      origin,
      fare,
      status,
      driverProfile,
      id,
      createdAt: date ? new Date(date.seconds * 1000) : null,
    };

    return rideObject;
  });

  // Handle duplicate rides
  const pendingRides = myRides.filter(ride => ride.status === 'pendingPickup');
  if (pendingRides.length > 1) {
    pendingRides.sort((a, b) => b.createdAt - a.createdAt);
    const latestRide = pendingRides[0];
    const duplicateRides = pendingRides.slice(1);

    // Update status of duplicate rides to 'riderCancelled'
    await Promise.all(duplicateRides.map(async (ride) => {
      const rideDoc = doc(firestore, "Rides", ride.id);
      await updateDoc(rideDoc, { status: 'riderCancelled' });
    }));

    // Keep only the latest ride with 'pendingPickup' status
    return [latestRide, ...myRides.filter(ride => ride.status !== 'pendingPickup')];
  }

  console.log(myRides);
  return myRides;
};

