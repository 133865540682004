import React from 'react';
import "./legalese.css"
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <div className='legalese'>
            <div className='float-right'>
                <Link to="/home">Return Home</Link>
            </div>
            

            <h1>Privacy Policy</h1>
            
            <p>Last Updated: July 31, 2024</p>

            <p>
                Thank you for choosing Rajlo, a ride-sharing platform connecting passengers with drivers in Jamaica. At Rajlo, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our website and mobile application (collectively referred to as the "Service").
            </p>

            <h2>1. Information We Collect</h2>

            <p>
                When you use Rajlo, we may collect the following types of information:
            </p>

            <p>
                <ul>
                    <li>
                        Personal Information: This may include your name, email address, phone number, payment information, and other details you provide when creating an account or using our service. 
                    </li>
                    <li>
                        Location Information: We collect location data to facilitate ride matching, navigation, and for safety and security purposes. 
                    </li>
                    <li>
                        Device Information: We may collect information about the device you use to access our service, including IP address, device type, operating system, and browser type.
                    </li>
                    <li>
                        Usage Information: We gather data on how you interact with our service, such as ride history, preferences, and feedback.
                    </li>
                </ul>
            </p>

            <h2>2. How We Use Your Information</h2>

            <p>We may use the information we collect for the following purposes:</p>

            <p>
                <ul>
                    <li>
                        To provide and improve our services, including matching you with drivers, processing payments, and optimizing the user experience.
                    </li>
                    <li>
                        To communicate with you about your account, transactions, and updates to our service.
                    </li>
                    <li>
                        To personalize your experience and provide tailored recommendations.
                    </li>
                    <li>
                        To ensure the safety and security of our users and prevent fraud or abuse.
                    </li>
                </ul>
            </p>

            <h2>3. Data Sharing and Disclosure</h2>

            <p>We may share your information with third parties under the following circumstances:</p>

            <p>
                <ul>
                    <li>
                        With drivers to facilitate ride bookings and fulfill transportation services.
                    </li>
                    <li>
                        With payment processors to process transactions securely.
                    </li>
                    <li>
                        With service providers and partners who assist us in operating our platform, including hosting services, analytics providers, and customer support.
                    </li>
                    <li>
                        In response to legal requests, such as court orders or subpoenas, or to comply with applicable laws and regulations.
                    </li>
                </ul>
            </p>

            <h2>4. Data Security</h2>

            <p>We take the security of your information seriously and employ measures to protect against unauthorized access, alteration, disclosure, or destruction of data. However, no method of transmission over the internet or electronic storage is entirely secure, so we cannot guarantee absolute security.</p>

            <h2>5. Your Choices</h2>

            <p>You have the right to access, update, and delete your personal information. You may also choose to opt out of certain communications or data collection methods, although this may impact your ability to use certain features of our service.</p>

            <h2>6. Children's Privacy</h2>

            <p>Rajlo is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children, and if we become aware that a child has provided us with their information, we will take steps to delete it.</p>

            <h2>7. Changes to this Privacy Policy</h2>

            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or through other means of communication.</p>

            <h2>8. Contact Us</h2>

            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@rajlo.com</p>
        </div>
    )
}

export default PrivacyPolicy