import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MapComponent from "../../components/MapComponent";
import { FaBars, FaSearch, FaStar, FaMapMarkerAlt } from "react-icons/fa";
import ProgressBar from "react-progressbar";
import { BsArrowLeftShort, BsFillRecord2Fill } from "react-icons/bs";
import { app, firestore } from "../../firebase";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { cancelRide } from "./../../services/TripServices";
import ngeohash from "ngeohash";
import "./SearchingForDrivers.css"; // Assuming styles are moved to this CSS file
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getFirestore,
  query,
  orderBy,
  startAt,
  endAt,
  where,
  onSnapshot,
} from "firebase/firestore";
import {
  geohashForLocation,
  geohashQueryBounds,
  distanceBetween,
  geofire,
  latitudeBitsForResolution,
} from "geofire-common";
const apiKey = process.env.REACT_APP_API_KEY;

// const firebaseConfig = {

//   apiKey: {apiKey},
//   authDomain: "rajlo-dev.firebaseapp.com",
//   databaseURL: "https://rajlo-dev-default-rtdb.firebaseio.com",
//   projectId: "rajlo-dev",
//   storageBucket: "rajlo-dev.appspot.com",
//   messagingSenderId: "421254217589",
//   appId: "1:421254217589:web:300e96f7ae1ef2d2b25d3c",
//   measurementId: "G-2BPBV32LF0"
// };

// const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//const db = getFirestore(app);

const SearchingForDrivers = () => {
  const navigate = useNavigate();
  const savedLat = localStorage.getItem("currentLat");
  const savedLng = localStorage.getItem("currentLng");
  const initialLat = savedLat ? parseFloat(savedLat) : null;
  const initialLng = savedLng ? parseFloat(savedLng) : null;
  const [listOfDrivers, setListOfDrivers] = useState([]);
  console.log(listOfDrivers);
  const { state } = useLocation();
  const [directions, setDirections] = useState(null);
  const { riderData } = state;
  const { sourceLat, sourceLng, destinationLat, destinationLng } = riderData;

  const continueButton = () => (
    <button onClick={onContinue} className="buttonStyle">
      Select Vehicle
    </button>
  );

  const onContinue = () => {
    navigate("/register");
  };

  const onMapLoad = (mapProps, map) => {
    const { google } = mapProps;

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: "red",
      },
    });
    const origin = new google.maps.LatLng(sourceLat, sourceLng);
    const destinationLatLng = new google.maps.LatLng(
      destinationLat,
      destinationLng
    );

    const request = {
      origin,
      destination: destinationLatLng,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      console.log("got directions result: ", result, status);
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
        directionsRenderer.setDirections(result); // Set directions to renderer
        directionsRenderer.setMap(map); // Render directions on the map
      } else {
        console.error("Error fetching directions:", status);
      }
    });
  };

  return (
    <div className="home-screen">
      <MapComponent
        lat={initialLat}
        lng={initialLng}
        listOfDrivers={listOfDrivers}
        onMapLoad={onMapLoad}
        directions={directions}
      />

      <div className="map-container">
        <div className="map-overlay">
          <div className="bookheader">
            <button className="back-button" onClick={() => navigate(-1)}>
              <BsArrowLeftShort className="headerback-button" />
            </button>
          </div>
          <Searching setListOfDrivers={setListOfDrivers} />
        </div>
      </div>
    </div>
  );
};

const Searching = ({ setListOfDrivers }) => {
  const navigate = useNavigate();
  const [progressing, setProgressing] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelRideLoading, setIsCancelRideLoading] = useState(false);
  const [isRideCancelled, setIsRideCancelled] = useState(false);
  const [isOpen, setOpen] = useState(true);

  const ridesCollection = collection(firestore, "Rides");
  const { state } = useLocation();
  const {
    riderData,
    miles,
    perMilePrice,
    vehicle_category,
    vehicle_type,
    tripId,
  } = state;
  const [intervalId, setIntervalId] = useState(null); // Define intervalId state

  useEffect(() => {
    // Function to increment progress
    getdriverlocation();
    const incrementProgress = () => {
      // setProgressing((prevProgress) => {
      //   if (prevProgress < 100) {
      //     return prevProgress + 10; // Increment by 10%
      //   } else {
      //     navigate('/driverdetail'); // Redirect when progress is complete
      //     return 100; // Ensure progress doesn't exceed 100%
      //   }
      // });
    };

    // Set interval to increment progress every second
    const id = setInterval(incrementProgress, 3000); // Store interval id in a variable
    setIntervalId(id); // Set interval id state

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]); // Dependency array includes navigate to avoid recreating interval on every render

  useEffect(() => {
    console.log("Trip ID", tripId);
    const subscribe = onSnapshot(doc(firestore, "Rides", tripId), (doc) => {
      if (doc.exists()) {
        const ride = doc.data();
        console.log("data changed", ride);
        if (ride.status === "driverEnroute") {
          // clearInterval(intervalId); // Clear interval using state
          navigate("/driverdetail", { state: { ride, riderData, tripId } });
        }
      }
    });

    return subscribe;
  }, [navigate, tripId]);

  const center = [state.riderData.sourceLat, state.riderData.sourceLng];
  const radiusInM = 8 * 10000;

  console.log("center-->", center);

  const getdriverlocation = () => {
    const q = query(collection(firestore, "location"), orderBy("g.geohash"));
    const riderGeoHash = geohashForLocation(center);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const lat_lng = [];
      querySnapshot.forEach((doc) => {
        const driverGeohash = doc.get("g.geohash");
        //should use endsWith method instead to cover the correct location
        if (driverGeohash.startsWith(riderGeoHash[0])) {
          const { latitude, longitude } = ngeohash.decode(driverGeohash);
          console.log(latitude, longitude);
          lat_lng.push([latitude, longitude]);
        }
      });
      setListOfDrivers(lat_lng);
    });
    // const bounds = geohashQueryBounds(center, radiusInM);
    // console.log("bounds---->", bounds);

    // const promises = [];

    // const q = query(collection(firestore, "location"), orderBy("g.geohash"));
    // console.log("value of q---->", q);
    // promises.push(getDocs(q));

    // const riderGeoHash = geohashForLocation(center);
    // console.log(riderGeoHash);

    // const matchingDocs = [];
    // const long_lat = [];
    // Promise.all(promises).then((snapshots) => {
    //   console.log(snapshots);
    //   if (snapshots.length == 0) {
    //     return;
    //   }

    //   for (let i = 0; i < snapshots[0].docs.length; i++) {
    //     console.log(i);
    //     const doc = snapshots[0].docs[i].get("g.geohash");
    //     matchingDocs.push(doc);
    // //   }
    //   for (let i = 0; i < matchingDocs.length; i++) {
    //     const driverGeohash = matchingDocs[i];
    //     console.log(driverGeohash);
    //     if (driverGeohash.startsWith(riderGeoHash[0])) {
    //       console.log(driverGeohash, riderGeoHash);
    //       const { latitude, longitude } = ngeohash.decode(driverGeohash);
    //       console.log(latitude, longitude);
    //       long_lat.push([latitude, longitude]);
    //     }
    //   }
    //   console.log(long_lat);
    //   setListOfDrivers(long_lat);
    // });

    // for (const snap of snapshots) {
    //      console.log("Doc-->",snap)

    //   // for (const doc of snap.docs) {
    //   //   console.log("Doc-->",doc)
    //   //   const lat = doc.get('lat');
    //   //   const lng = doc.get('lng');
    //   //   const distanceInKm = geofire.distanceBetween([lat, lng], center);
    //   //   const distanceInM = distanceInKm * 1000;
    //   //   if (distanceInM <= radiusInM) {
    //   //     matchingDocs.push(doc);
    //   //   }
    //   // }
    // }
  };

  const handleCancellation = async () => {
    setIsCancelRideLoading(true);
    const rideCancelled = await cancelRide(tripId);

    if (rideCancelled.success) {
      setIsRideCancelled(true);
      setTimeout(() => {
        setIsRideCancelled(false);
        navigate("/home");
      }, 5000);
    }
    setIsCancelRideLoading(false);
  };

  const cancelRideUi = () => (
    <button
      onClick={handleCancellation} // Navigate to the home route when the button is clicked
      className="buttonStyleFw"
      disabled={isCancelRideLoading}
    >
      {isCancelRideLoading ? (
        <div className="inline-loader">
          <div className="loader-btn"></div>
        </div>
      ) : (
        "Cancel Ride"
      )}
    </button>
  );

  const progressComponent = () => (
    <div style={{ margin: "5px 25px" }}>
      <ProgressBar
        completed={progressing}
        style={{
          color: "#333", 
          height: "8px",
          borderRadius: "0px", 
        }}
      />
    </div>
  );

  const Loader = () => {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  };

  // Add any logic or state related to recent destinations here
  return (
    <div className="recent-destinations-container">
      <div
        className="recent-destinations-bar"
        onClick={() => setOpen(!isOpen)}
      ></div>
      {isOpen && (
        <div>
          <div className="">
            <div
              style={{
                alignItems: "center",
                marginTop: "20px" /* Adjust as needed */,
              }}
            >
              {/* <img
              src={"/assets/images/searchDriver.png"} // Adjust the path as needed
              style={{ width: "10%", height: "auto", objectFit: "contain" }} // Adjust height as needed
            /> */}
              {!isRideCancelled && (
                <p
                  style={{
                    color: "black",
                    fontSize: "16px",
                    textAlign: "center",
                    margin: "20px" /* Adjust as needed */,
                  }}
                >
                  Hold on! We are searching
                  <br />
                  nearby drivers for you...
                </p>
              )}
              {isRideCancelled && (
                <p
                  style={{
                    color: "black",
                    fontSize: "16px",
                    textAlign: "center",
                    margin: "20px" /* Adjust as needed */,
                  }}
                >
                  Ride canceled.
                </p>
              )}
            </div>
            {!isRideCancelled && progressComponent()}
            {!isRideCancelled && <Loader />}
          </div>

          <div style={{ marginBottom: 40, marginTop: 30, display: "flex" }}>
            <div style={{ flex: "1", marginRight: "10px", marginLeft: 10 }}>
              {isRideCancelled ? "" : cancelRideUi()}
            </div>
          </div>

          {isLoading && (
            <div className="loading-overlay">
              <div className="loader"></div>
              <p> Searching nearby drivers...</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchingForDrivers;
