import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FiPlusCircle } from "react-icons/fi";

const getPaymentIcon = (cardBrand) => {
  let cardLogo = "";
  switch (cardBrand) {
    case "visa":
      cardLogo = "/assets/images/paymentMethods/visa.png";
      break;
    case "mastercard":
      cardLogo = "/assets/images/paymentMethods/masterCard.png";
      break;
    case "wallet":
      cardLogo = "/assets/images/paymentMethods/wallet.png";
      break;
    default:
      cardLogo = "/assets/images/paymentMethods/wallet.png";
      break;
  }

  return cardLogo;
};

const PaymentMethodItem = ({
  paymentMethods,
  selectedPaymentMethodIndex,
  onSelectPaymentMethod,
  onAddNewMethod,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Dropdown onSelect={onSelectPaymentMethod}>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          <img
            src={getPaymentIcon(
              paymentMethods[selectedPaymentMethodIndex].brand
            )}
            alt="Payment Icon"
            style={{ width: "30px", height: "30px", objectFit: "contain" }}
          />
          {"**** **** **** " +
            paymentMethods[selectedPaymentMethodIndex].last4}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {paymentMethods.map((method, index) => (
            <Dropdown.Item eventKey={index} key={index}>
              <img
                src={getPaymentIcon(method.brand)}
                alt="Payment Icon"
                style={{
                  width: "30px",
                  height: "30px",
                  objectFit: "contain",
                  marginRight: "8px",
                }}
              />
              {"**** **** **** " + method.last4}
            </Dropdown.Item>
          ))}
          <Dropdown.Item onClick={onAddNewMethod}>
            <FiPlusCircle className="plusicon" />
            Add New Method
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default PaymentMethodItem;
