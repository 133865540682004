import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import "./LoginScreen.css";
import { firestore } from "../../firebase";
import { sendOtp, initRecaptcha, recaptcha, resetRecaptcha } from "../../services/otpService";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [backClickCount, setBackClickCount] = useState(0);
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mainLoader, setMainLoader] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // Sample country codes - extend this list as needed
  const countryCodes = ["+1", "+91"];

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      backClickCount === 1 ? window.close() : _spring();
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => window.removeEventListener("popstate", handleBackButton);
  }, [backClickCount]);

  useEffect(() => {
    initRecaptcha(); 

    // return () => {
    //   resetRecaptcha();
    // };
  }, []); 

  function _spring() {
    setBackClickCount(1);
    setTimeout(() => {
      setBackClickCount(0);
    }, 1000);
  }
  
  const onContinue = async () => {
    setMainLoader(true);
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;

    // Validation
    if (!phoneNumber) {
      setMainLoader(false);
      setAlertMessage("Please enter a phone number.");
      return;
    }

    if (phoneNumber.length !== 10) {
      setMainLoader(false);
      setAlertMessage("Please enter a 10-digit phone number.");
      return;
    }

    // Check if phone exists in Db
    const phoneQuery = query(
      collection(firestore, "User"),
      where("phone", "==", fullPhoneNumber),
      // where("role", "==", "rider")
    );
    const querySnapshot = await getDocs(phoneQuery);

    if (querySnapshot.empty) {
      setMainLoader(false);
      setAlertMessage("Phone number is not registered.");
      navigate("/register", {
        state: { phoneNumber: phoneNumber, countryCode: countryCode },
      });
      return;
    }

    // Send OTP
    try {
      // initRecaptcha(); 
      const confirmResult = await sendOtp(fullPhoneNumber);
      console.log("onContinue confirmation", confirmResult);
      setMainLoader(false);
      navigate("/verify", { state: { phoneNumber: fullPhoneNumber } });
    } catch (error) {
      console.log("onContinue: ", error);
      setMainLoader(false);
      setAlertMessage(
        "Error sending phone verification. Please contact administrator."
      );
    }
  };

  const continueButton = () => (
    <button
      onClick={onContinue}
      className='loginbuttonStyle'
      id='recaptcha-btn'
      style={{ textDecoration: "none" }}
      disabled={mainLoader}
    >
      {mainLoader ? (
        <div className='inline-loader'>
          <div className='loader-btn'></div>
        </div>
      ) : (
        "Continue"
      )}
    </button>
  );

  const countryCodesDropdown = () => (
    <select
      value={countryCode}
      onChange={(e) => setCountryCode(e.target.value)}
      className='countryCodeDropdown'
    >
      {countryCodes.map((code) => (
        <option
          key={code}
          value={code}
        >
          {code}
        </option>
      ))}
    </select>
  );

  const mobileNumberInfo = () => (
    <div className='centeredContainer'>
      <div className='welcomeInfoContainer'>
        <h1 className='title'>Welcome to Rajlo</h1>
      </div>
      {alertMessage && <div className='alertMessageStyle'>{alertMessage}</div>}
      <div className='subtitle'>Enter your phone number to continue</div>
      <div className='phoneInputContainer'>
        {countryCodesDropdown()}
        <input
          type='tel'
          placeholder='Enter Your Number'
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className='phoneInputStyle'
        />
      </div>
      {continueButton()}
    </div>
  );

  const loginImage = () => (
    <img
      src={"/assets/images/login.png"}
      alt='Login'
      className='login-image'
    />
  );

  return (
    <div className='safeArea'>
      <div
        className='logincontent'
        style={{ flex: 1 }}
      >
        {loginImage()}
        {mobileNumberInfo()}
      </div>
    </div>
  );
};

export default LoginScreen;
