import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export const fetchUserData = async () => {
    let uid = JSON.parse(localStorage.getItem("userLogin")).uid
    if (uid) {
      const userRef = doc(firestore, "User", uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();

        return userData;
      
      } else {
        console.log("No such document!");
      }
    } else {
      console.log("No user id available");
    }
  };