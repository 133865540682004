import React, { useState, useEffect } from 'react';

const InternetStatus = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div>
      {isOnline ? (
        null
      ) : (
        <div className='noInternet'>
          <p>You are offline. Please check your internet connection.</p>
        </div>
      )}
    </div>
  );
};

export default InternetStatus;
