import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MapComponent from "../../components/MapComponent";
import { BsArrowLeftShort } from "react-icons/bs";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { calculateFareForTrip } from "../../services/TripServices";

import "./SelectVehicle.css"; // Assuming styles are moved to this CSS file
const vehicleTypes = [
  {
    id: 1,
    type: "Rajlo X",
    price: "",
    mileprice: "0.95",
    category: "noTabs",
    image: "/assets/images/cabs/cab1.png",
  },
  {
    id: 2,
    type: "Rajlo Route",
    price: "",
    mileprice: "0.80",
    category: "noTabs",
    image: "/assets/images/cabs/cab2.png",
  },
];

const vehicleTypeTabs = [
  { key: "Rajlo X", title: "Rajlo X" },
  { key: "Rajlo Route", title: "Rajlo Route" },
];

const SelectVehicle = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { sourceLat, sourceLng, destinationLat, destinationLng } = state;
  const savedLat = localStorage.getItem("currentLat");
  const savedLng = localStorage.getItem("currentLng");
  const [directions, setDirections] = useState(null);

  // Convert them back to numbers since localStorage stores everything as strings
  const initialLat = savedLat ? parseFloat(savedLat) : null;
  const initialLng = savedLng ? parseFloat(savedLng) : null;

  const onContinue = () => {
    navigate("/register");
  };

  const onMapLoad = (mapProps, map) => {
    const { google } = mapProps;

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: "red",
      },
    });
    const origin = new google.maps.LatLng(sourceLat, sourceLng);
    const destinationLatLng = new google.maps.LatLng(
      destinationLat,
      destinationLng
    );

    const request = {
      origin,
      destination: destinationLatLng,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      console.log("got directions result: ", result, status);
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
        directionsRenderer.setDirections(result); // Set directions to renderer
        directionsRenderer.setMap(map); // Render directions on the map
      } else {
        console.error("Error fetching directions:", status);
      }
    });
  };

  return (
    <div className="home-screen">
      <MapComponent
        lat={initialLat}
        lng={initialLng}
        onMapLoad={onMapLoad}
        directions={directions}
      />

      <div className="map-container">
        <div className="map-overlay">
          <div className="bookheader">
            <button className="back-button" onClick={() => navigate(-1)}>
              <BsArrowLeftShort className="headerback-button" />
            </button>
            <h1 className="booktext m-2">Choose a Cab</h1>
          </div>
          <VehicleTypes />
        </div>
      </div>
    </div>
  );
};
let totalPrice;
let perMilePrice;
let vehicle_type = "Rajlo Route"; // Default selected vehicle type
let vehicle_category = "Rajlo Route"; // Default selected vehicle category
let allData = vehicleTypes.find((vehicle) => vehicle.type === "Rajlo Route"); // Default selected vehicle data

const VehicleTypes = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    riderData,
    miles,
    pickupAddress,
    destinationAddress,
    sourceLat,
    sourceLng,
    destinationLat,
    destinationLng,
    distanceText,
    durationText,
  } = state;
  const [isOpen, setOpen] = useState(true);

  const [fare, setFare] = useState(0);
  const [rajloXFare, setFareRajloX] = useState(0);
  const [distance, setDistance] = useState(0);
  const [selectedVehicleId, setSelectedVehicleId] = useState(
    vehicleTypes.find((v) => v.type === "Rajlo Route").id
  );

  useEffect(() => {
    const getFare = async (pickupAddress, destinationAddress) => {
      if (!pickupAddress || !destinationAddress) {
        return navigate("/home");
      }

      const tripFare = await calculateFareForTrip(
        pickupAddress,
        destinationAddress
      );
      setFare(tripFare.data.fares.routeTaxi);
      setDistance(tripFare.data.distance);
      setFareRajloX(tripFare.data.fares.rajloX);
    };

    getFare(pickupAddress, destinationAddress);
  }, [pickupAddress, destinationAddress]);

  const onBookRide = () => {
    let ride = {
      riderData,
      miles,
      perMilePrice,
      vehicle_category,
      vehicle_type,
      fare,
      rajloXFare,
      distance,
      sourceLat,
      sourceLng,
      destinationLat,
      destinationLng,
      distanceText,
      durationText,
    };

    navigate("/payment", {
      state: ride,
    });
  };

  const onCardClick = (vehicleId, vehicleData) => {
    setSelectedVehicleId(vehicleId);
    allData = vehicleData;
    vehicle_type = vehicleData.type;
    vehicle_category = vehicleData.category;
    perMilePrice = vehicleData.mileprice;
  };

  return (
    <div className="recent-destinations-container">
      <div
        className="recent-destinations-bar"
        onClick={() => setOpen(!isOpen)}
      ></div>
      {isOpen && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {vehicleTypes
              .filter((vehicleType) => vehicleType.category === "noTabs")
              .map((destination, index) => (
                <Card
                  style={{
                    width: "8rem",
                    marginTop: 40,
                    marginRight: 10,
                    border:
                      selectedVehicleId === destination.id
                        ? "3px solid red"
                        : "1px solid black",
                  }}
                  key={index}
                >
                  <div style={{ alignItems: "center" }}>
                    <img
                      src={destination.image}
                      alt={destination.type}
                      style={{
                        width: 60,
                        textAlign: "center",
                        margin: -40,
                      }}
                    />
                  </div>
                  <Card.Body
                    style={{
                      paddingTop: 50,
                      width: "100%",
                      height: 130,
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    onClick={() => onCardClick(destination.id, destination)}
                  >
                    <div style={{ textAlign: "center" }}>
                      <h6>{destination.type}</h6>
                    </div>
                    <div style={{ fontWeight: "bold", textAlign: "center" }}>
                      {destination.type === "Rajlo X" && (
                        <h6 style={{ marginBottom: 0, fontWeight: "bold" }}>
                          ${rajloXFare.toFixed(2)}
                        </h6>
                      )}
                      {destination.type === "Rajlo Route" && (
                        <h6 style={{ marginBottom: 0, fontWeight: "bold" }}>
                          ${fare.toFixed(2)}
                        </h6>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              ))}
          </div>
          <div style={{ marginBottom: 40, marginTop: 30 }}>
            <button onClick={onBookRide} className="buttonStyleFw">
              Book Ride
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const vehicleTabs = (
  vehicleTypeTabs,
  vehicleTypes,
  rajloXFare,
  fare,
  onChange
) => {
  return (
    <Tabs
      defaultActiveKey="Rajlo Route"
      id="uncontrolled-tab-example"
      className="mb-3"
      style={{
        border: 0,
        width: "100%",
        justifyContent: "center",
        fontSize: 18,
      }}
    >
      {vehicleTypeTabs.map((tab, index) => (
        <Tab
          eventKey={tab.key}
          title={tab.title}
          style={{ border: 0 }}
          key={index}
        >
          <div className="tab-container">
            {vehicleTypes
              .filter((vehicleType) => vehicleType.category === tab.key)
              .map((destination, index) => (
                <Card
                  style={{
                    width: "7rem",
                    marginTop: 40,
                    marginRight: 10,
                  }}
                  key={index}
                >
                  <div style={{ alignItems: "center" }}>
                    <img
                      src={destination.image}
                      alt={destination.type}
                      style={{
                        width: 60,
                        textAlign: "center",
                        margin: -40,
                      }}
                    />
                  </div>
                  <Card.Body style={{ paddingTop: 50 }}>
                    <div style={{ textAlign: "left" }}>
                      <h6>{destination.type}</h6>
                      {destination.category === "Rajlo X" && (
                        <h6>${rajloXFare.toFixed(2)}</h6>
                      )}
                      {destination.category === "Rajlo Route" && (
                        <h6>${fare.toFixed(2)}</h6>
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        marginTop: -20,
                        marginRight: -20,
                        marginBottom: -10,
                      }}
                    >
                      <Form.Check
                        inline
                        name="group1"
                        value={JSON.stringify(destination)}
                        type="radio"
                        id={destination.id}
                        onChange={onChange}
                        defaultChecked={destination.type === "Rajlo Route"}
                      />
                    </div>
                  </Card.Body>
                </Card>
              ))}
          </div>
        </Tab>
      ))}
    </Tabs>
  );
};

export default SelectVehicle;
