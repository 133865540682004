import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import Form from "react-bootstrap/Form";
import "./PaymentMethod.css";
import fetchPaymentMethods from "../../services/paymentService";
import PaymentMethodListItem from "../../components/PaymentMethodListItem";

const PaymentMethod = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchPaymentMethods().then((paymentMethodsArray) => {
      console.log("PaymentMethod Page Load fetchPaymentMethods: ", paymentMethodsArray);
      setPaymentMethods(paymentMethodsArray || []);
      setIsLoading(false);
    });
  }, []);

  const reloadCards = () => {
    fetchPaymentMethods().then((paymentMethodsArray) => {
      console.log("reloadCards fetchPaymentMethods: ", paymentMethodsArray);
      setPaymentMethods(paymentMethodsArray || []);
      setIsLoading(false);
    });
  };

  const handleContinue = () => {
    navigate("/addnewmethod");
  };

  const continueRide = () => (
    <button onClick={handleContinue} className="paymentbuttonStyle">
      <FiPlusCircle className="plusicon" />
      Add New Method
    </button>
  );

  return (
    <div className="home-screen">
      <div className="">
        <div className="">
          <div className="methodheader">
            <button className="back-button" onClick={() => navigate(-1)}>
              <BsArrowLeftShort className="headerback-button" />
            </button>
            <h1 className="methodtext m-2">Payment Method</h1>
          </div>
          {!isLoading && paymentMethods && <PaymentMethodsList paymentMethods={paymentMethods} reloadCards={reloadCards} />}
          <div className="paymentbuttondiv">{continueRide()}</div>
        </div>
      </div>
    </div>
  );

  function PaymentMethodsList({ paymentMethods, reloadCards }) {

    if (!paymentMethods || paymentMethods.length === 0) {
      return (
        <div className="empty-text">
          <p>No payment methods available. Please add a new method.</p>
        </div>
      );
    }

    return (
      <div style={{ marginTop: "16px", margin: "16px" }}>
        {paymentMethods && paymentMethods.length > 0 &&
          paymentMethods.map((item, index) => (
            <PaymentMethodListItem paymentMethodItem={item} key={index} reloadCards={reloadCards} />
          ))}
      </div>
    );
  }
};

export default PaymentMethod;
