import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MapComponent from "../../components/MapComponent";
import "./DriverDetail.css";
import {
  BsArrowLeftShort,
  BsFillRecord2Fill
} from "react-icons/bs";
import {
  FaBars,
  FaSearch,
  FaBullseye,
  FaMapMarker,
  FaMapPin,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { IoIosArrowUp, IoIosCall, IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import Form from "react-bootstrap/Form";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { color } from "framer-motion";
import { firestore } from "../../firebase";
import { cancelRide } from "../../services/TripServices";

const DriverDetail = () => {
  const navigate = useNavigate();
  const savedLat = localStorage.getItem("currentLat");
  const savedLng = localStorage.getItem("currentLng");
  const { state } = useLocation();
  const [directions, setDirections] = useState(null);
  const [googleProp, setGoogleProp] = useState(null);
  const [googleMap, setGoogleMap] = useState(null);
  const [driverLat, setDriverLat] = useState(null);
  const [driverLng, setDriverLng] = useState(null);
  const { ride, riderData } = state;
  const { sourceLat, sourceLng, destinationLat, destinationLng } = riderData;
  const { destination } = ride;
  // Convert them back to numbers since localStorage stores everything as strings
  const initialLat = savedLat ? parseFloat(savedLat) : null;
  const initialLng = savedLng ? parseFloat(savedLng) : null;

  const loadDirections = ({ google, map, driverLat, driverLng }) => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      map,
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: "red",
      },
    });
    const driverOrigin = new google.maps.LatLng(driverLat, driverLng);
    const driverDestination = new google.maps.LatLng(sourceLat, sourceLng);
  
    const request = {
      origin: driverOrigin,
      destination: driverDestination,
      travelMode: google.maps.TravelMode.DRIVING,
    };
  
    directionsService.route(request, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
        directionsRenderer.setDirections(result);
        const leg = result.routes[0].legs[0];
        if (leg) {
          makeMarker(leg.start_location, "/assets/images/DriverIcon.png");
        }
      } else {
        console.error("Error fetching directions:", status);
      }
    });
  };

  const makeMarker = (position, icon) => {
    if (googleProp && googleMap) {
      new googleProp.maps.Marker({
        map: googleMap,
        position: position,
        icon: icon,
      });
    }
  };

  useEffect(() => {
    if (googleProp && googleMap && driverLat && driverLng) {
      loadDirections({
        google: googleProp,
        map: googleMap,
        driverLat,
        driverLng,
      });
    }
  }, [googleProp, googleMap, driverLat, driverLng]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, "location", ride.driver),
      (doc) => {
        if (doc.exists()) {
          const loc = doc.data();
          const { _lat, _long } = loc.g.geopoint;
          console.log(
            "got diver location: ",
            loc,
            _lat,
            _long,
            googleMap,
            googleProp
          );
          setDriverLat(_lat);
          setDriverLng(_long);
        }
      }
    );

    return unsubscribe;
  }, [ride, googleMap, googleProp]);

  const onMapLoad = (mapProps, map) => {
    const { google } = mapProps;
    setGoogleProp(google);
    setGoogleMap(map);
  };

  return (
    <div className="home-screen">
      <MapComponent
        lat={initialLat}
        lng={initialLng}
        onMapLoad={onMapLoad}
        directions={directions}
      />

      <div className="map-container">
        <div className="map-overlay">
          <div className="driverheader">
            <button className="driverback-button" onClick={() => navigate(-1)}>
              <BsArrowLeftShort className="driverback-button1" />
              Driver Detail
            </button>
          </div>
          <DriverDetailSection directions={directions} />
        </div>
      </div>
    </div>
  );
};

const DriverDetailSection = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showMore, setShowMore] = useState(false); // State to track MoreDetail visibility
  const [driverDetail, setDriverDetail] = useState({});
  const { ride, riderData, tripId } = state;
  const [isOpen, setOpen] = useState(true);
  const [isCancelRideLoading, setIsCancelRideLoading] = useState(false);
  const [isRideCancelled, setIsRideCancelled] = useState(false);

  const { directions } = props;
  console.log(state);

  useEffect(() => {
    getDriverInfo(ride.driver);
  }, [ride]);

  useEffect(() => {
    const subscribe = onSnapshot(doc(firestore, "Rides", tripId), (doc) => {
      if (doc.exists()) {
        const ride = doc.data();
        if (ride.status === "passengerEnroute") {
          navigate("/endride", {
            state: { ride: ride, tripId: tripId, riderData },
          });
        }
      }
    });

    return subscribe;
  }, [navigate, tripId]);

  const getDriverInfo = async (driverId) => {
    console.log("gettig driver info: ", driverId, ride);
    const driverDocRef = doc(firestore, "User", driverId);
    const driverDoc = await getDoc(driverDocRef);

    if (driverDoc.empty) {
      throw new Error("There is no information for this driver");
    }
    const driver = driverDoc.data();

    const color = driver.vehicle && driver.vehicle.color ? driver.vehicle.color: "";
    const make = driver.vehicle && driver.vehicle.make ? driver.vehicle.make: "";
    const model = driver.vehicle && driver.vehicle.model ? driver.vehicle.model: "";
    const vehicleNumber = driver.vehicle && driver.vehicle.vehicleNumber ? driver.vehicle.vehicleNumber: "";

    const driverDetail = {
      id: driverId,
      name: driver.name || "",
      image: driver.image || "",
      color: color,
      vehicle: make + " " + model,
      licensePlate: vehicleNumber,
    };
    console.log(driverDetail);

    setDriverDetail(driverDetail);
  };

  const onMore = () => {
    setShowMore(!showMore); // Toggle the visibility of MoreDetail
  };

  const moreDetail = () => (
    <button onClick={onMore} className="moreBtn">
      <span style={{ color: "red", fontSize: "20px", marginRight: "9px" }}>
        {showMore ? (
          <IoIosArrowDown className="moreicon" />
        ) : (
          <IoIosArrowUp className="moreicon" />
        )}
      </span>
      {showMore ? "Less" : "More"}
    </button>
  );

  const onCancelRide = () => {
    console.log("Clicked");
    navigate("/endride");
  };

  const handleCancellation = async () => {
    setIsCancelRideLoading(true);
    const rideCancelled = await cancelRide(tripId);

    if (rideCancelled.success) {
      setIsRideCancelled(true);
      setTimeout(() => {
        setIsRideCancelled(false);
        navigate("/home");
      }, 5000);
    }
    setIsCancelRideLoading(false);
  };

  const cancelRideUi = () => (
    <button
      onClick={handleCancellation} // Navigate to the home route when the button is clicked
      className="buttonStyleFw"
      disabled={isCancelRideLoading}
    >
      {isCancelRideLoading ? (
        <div className="inline-loader">
          <div className="loader-btn"></div>
        </div>
      ) : (
        "Cancel Ride"
      )}
    </button>
  );

  // Add any logic or state related to recent destinations here
  return (
    <div className="recent-destinations-container">
      {isRideCancelled && (
        <p
          style={{
            color: "black",
            fontSize: "16px",
            textAlign: "center",
            margin: "20px" /* Adjust as needed */,
          }}
        >
          Ride canceled.
        </p>
      )}
      <div
        className="recent-destinations-bar"
        onClick={() => setOpen(!isOpen)}
      ></div>
      {isOpen && (
        <div>
          <div className="">
            <div
              style={{
                alignItems: "center",
                marginTop: "20px" /* Adjust as needed */,
              }}
            >
              {/* <IoIosCall className="call-icon" /> */}
              <img
                src={driverDetail?.image || "assets/images/DriverIcon.png"}
                alt="driver detail" // Adjust the path as needed
                className="driver-image" // Adjust height as needed
              />
              {/* <BsFillChatLeftTextFill  className="text-icon"/> */}

              <div className="driver-name">{driverDetail?.name}</div>
              <div className="vehicle-description-container">
                <div className="vehicle-description mb-4">
                  <div className="vehicle-description-item">
                    <div className="vehicle-description-item-subtitle">
                      {driverDetail.color} {driverDetail?.vehicle}
                    </div>
                    <div className="vehicle-description-item-details">
                      {driverDetail.licensePlate}
                    </div>
                  </div>
                  <div className="vehicle-description-item">
                    <div className="vehicle-description-item-subtitle">
                      Arriving in
                    </div>
                    <div className="vehicle-description-item-details">
                      {directions?.routes
                        ? directions.routes[0].legs[0].duration.text
                        : "..."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showMore && <MoreDetail />}

          {!isRideCancelled && (
            <div style={{ marginBottom: 40, marginTop: 30, display: "flex" }}>
              <div style={{ flex: "1" }}>{moreDetail()}</div>
              <div style={{ flex: "1.8", marginTop: 5 }}> {cancelRideUi()}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const MoreDetail = () => {
  const { state } = useLocation();
  const { ride } = state;

  const destinations = [
    {
      name: "",
      address: ride.origin.address,
      destinationType: "departure",
    },
    {
      name: "",
      address: ride.destination.address,
      destinationType: "arrival",
    },
  ];

  // Implement the details you want to show in this component
  return (
    <div className="more-detail-container">
      <div style={{ display: "flex", marginInline: "10px", marginTop: "15px" }}>
        <div
          style={{
            flex: 0.6,
            textAlign: "left",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          Trip Route
        </div>
        <div
          style={{
            flex: 0.4,
            textAlign: "right",
            color: "red",
            fontSize: "13px",
            fontWeight: "500",
          }}
        >
          {ride.miles}km
        </div>
      </div>
      <ul className="driverrecent-destinations">
        {destinations.map((destination, index) => (
          <li key={index} className="driverbooking-destination-item mt-2">
            <div className="booking-destination-icon">
              {destination.destinationType === "departure" ? (
                <div>
                  <BsFillRecord2Fill
                    style={{ marginRight: "5px", fontSize: "29px" }}
                  />
                </div>
              ) : destination.destinationType === "arrival" ? (
                <FaMapMarkerAlt
                  style={{ marginRight: "5px", color: "red", fontSize: "20px" }}
                />
              ) : (
                <FaMapMarkerAlt
                  style={{ marginRight: "5px", fontSize: "20px" }}
                />
              )}
            </div>
            <div className="destination-item-detail">
              <div className="driverdestination-name">
                {destination.address}
              </div>

              {/* <div className="destination-name" >{destination.name}</div> */}
              {/* <div className="destination-address">{destination.address}</div> */}
            </div>
          </li>
        ))}
      </ul>

      <PaymentMethodsInfo />
      {/* <PaymentMethodsInfo paymentmethods={paymentmethods} /> */}

      <div className="othercard">
        <div className="triphrline mb-3"></div>
        Other Info
      </div>
      <div className="infocard g-5 g-xl-10 mb-5 mt-2">
        <div className="infodiv">
          Payment via
          <div className="infovalue">Credit Card</div>
        </div>
        <div className="infodiv">
          Ride fare
          <div className="infovalue">${ride.fare.toFixed(2)}</div>
        </div>
        <div className="infodiv">
          Ride type
          <div className="infovalue">{ride.vehicle_type}</div>
        </div>
      </div>
    </div>
  );
};

const PaymentMethodsInfo = () => {
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(null);
  const { state } = useLocation();
  const { ride } = state;
  const [paymentInfo, setPaymentInfo] = useState([]);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = async () => {
    const userString = localStorage.getItem("userLogin");
    if (!userString) {
      throw new Error("User inside application is unauthorized ");
    }

    const user = JSON.parse(userString);

    const paymentOptionsQuery = doc(firestore, "User", user.uid);
    const querySnapshot = await getDoc(paymentOptionsQuery);

    if (querySnapshot.empty) {
      throw new Error("There is no information for this user");
    }
    const { payments } = querySnapshot.data();
    console.log(payments);

    setPaymentInfo(payments || []);
    console.log("got payment info: ", paymentInfo, payments);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {/* <h2 style={{ marginBottom: '8px', fontWeight: 'bold', textAlign: 'left', fontSize: '16px' }}>
        Payments
      </h2> */}
      <div className="triphrline mb-3"></div>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <div className="paymentcard" style={{ flex: 0.6 }}>
          Payments
        </div>

        <div
          style={{
            flex: 0.4,
            textAlign: "right",
            color: "red",
            fontSize: "13px",
            fontWeight: "500",
          }}
        >
          ${ride.fare.toFixed(2)}
        </div>
      </div>
      {paymentInfo.map((item, index) => (
        <div
          key={index}
          // key={item.id}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px",
            border: "1px solid #E6E6E6",
            marginBottom: "8px",
            borderRadius: "6px",
            backgroundColor:
              selectedPaymentMethodIndex === index ? "#333" : "transparent",
            color: selectedPaymentMethodIndex === index ? "white" : "black",
          }}
        >
          <img
            src={"/assets/images/paymentMethods/visa.png"}
            // src={item.paymentIcon}
            alt="Payment Icon"
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
          />
          {item.type === "card" ? (
            <div style={{ marginLeft: "8px", flex: 1 }}>
              <div
                style={{
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textAlign: "left",
                  textOverflow: "ellipsis",
                }}
              >
                **** **** **** {item.last4}
              </div>
              <div
                style={{
                  fontWeight: "200",
                  whiteSpace: "nowrap",
                  fontSize: "11px",
                  overflow: "hidden",
                  textAlign: "left",
                  textOverflow: "ellipsis",
                  color: "black",
                }}
              >
                {item.cardExpiryMonth} / {item.cardExpiryYear}
              </div>
            </div>
          ) : (
            <div
              style={{
                marginLeft: "8px",
                flex: 1,
                fontWeight: "600",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textAlign: "left",
                textOverflow: "ellipsis",
              }}
            >
              {item.paymentMethod}
            </div>
          )}
          <Form.Check
            inline
            name="group1"
            type="radio"
            id={item.id}
            style={{ color: "red" }}
          />
          {/* <div
            style={{
              backgroundColor: selectedPaymentMethodIndex === index ? '#555' : '#E6E6E6',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {selectedPaymentMethodIndex === index && <span>&#10003;</span>}
          </div> */}
        </div>
      ))}
    </div>
  );
};
export default DriverDetail;
