// otpService.js

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";

export let recaptcha;
export let confirmationResult;
let widgetId;

export const initRecaptcha = async () => {
  try {
    if (recaptcha) {
      console.log("Recaptcha already exist for this element: ", recaptcha);
      return;
    }

    if (!recaptcha) {
      recaptcha = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("Recaptcha resolved with response: ", response);
        },
      }); // Ensure you pass the `auth` instance to RecaptchaVerifier

      console.log("initRecaptcha recaptcha: ", recaptcha);

      // // Render Recaptcha
      widgetId = await recaptcha.render();
      console.log("Recaptcha rendered successfully");
    }
  } catch (error) {
    console.error("initRecaptcha error: ", error);
    throw new Error(`initRecaptcha error: ${error}`);
  }
};

export const sendOtp = async (fullPhoneNumber) => {
  try {
    if (!recaptcha) {
      throw new Error("Recaptcha not initialized");
    }

    // SignInWithPhoneNumber
    const confirmationResponse = await signInWithPhoneNumber(
      auth,
      fullPhoneNumber,
      recaptcha
    );

    confirmationResult = confirmationResponse;

    console.log(
      "signInWithPhoneNumber confirmationResult: ",
      confirmationResult
    );

    return confirmationResult;
  } catch (error) {
    console.error("sendOtp setupRECAPTCHA error: ", error);
    throw new Error(`sendOtp setupRECAPTCHA error: ${error}`);
  }
};

export const resetRecaptcha = () => {
  if (recaptcha) {
    // Ensure to clear the reCAPTCHA widget if it exists.
    recaptcha.reset();
    console.log("Recaptcha reset successfully.");
  }
  recaptcha._widgetId = undefined;
  recaptcha = null;
  
  confirmationResult = null;
};
