import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import MapComponent from "../../components/MapComponent";
import "./BookRide.css";
import {
  FaBars,
  FaSearch,
  FaBullseye,
  FaMapMarker,
  FaMapPin,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { BsArrowLeftShort, BsFillRecord2Fill } from "react-icons/bs";
import * as geolib from "geolib";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getFirestore,
} from "firebase/firestore";
const apiKey = process.env.REACT_APP_API_KEY;
const destinations = [
  {
    name: "",
    address: "",
    destinationType: "departure",
  },
  {
    name: "",
    address: "",
    destinationType: "arrival",
  },
];
let calculatedDistance;
let distanceCalculated;
let miles;

const BookRide = (props) => {
  const { state } = useLocation();
  const {
    sourceLat,
    sourceLng,
    sourceAddress,
    destinationLat,
    destinationLng,
    destinationAddress,
    pickupAddress,
  } = state;
  const params = useParams();
  const navigate = useNavigate();
  const { radius, latitude, longitude } = useState("");
  const [users, setUsers] = useState([]);
  const [directions, setDirections] = useState(null);

  const savedLat = localStorage.getItem("currentLat");
  const savedLng = localStorage.getItem("currentLng");

  console.log(savedLat);
  console.log(savedLng);

  // Convert them back to numbers since localStorage stores everything as strings
  const initialLat = savedLat ? parseFloat(savedLat) : null;
  const initialLng = savedLng ? parseFloat(savedLng) : null;
  useEffect(() => {
    getDistanceOneToOne();
  });

  const getDistanceOneToOne = () => {
    const distance = geolib.getDistance(
      { latitude: sourceLat, longitude: sourceLng },
      { latitude: destinationLat, longitude: destinationLng }
    );
    console.log("Distancee", distance);
    calculatedDistance = distance;
    const milesCalculated = Math.round(geolib.convertDistance(distance, "mi"));
    miles = milesCalculated;
    console.log("miles", miles);
  };

  const onMapLoad = (mapProps, map) => {
    const { google } = mapProps;

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: "red"
      }
    });
    const origin = new google.maps.LatLng(sourceLat, sourceLng);
    const destinationLatLng = new google.maps.LatLng(destinationLat, destinationLng);

    const request = {
      origin,
      destination: destinationLatLng,
      travelMode: google.maps.TravelMode.DRIVING
    };

    console.log("routing origin vs destination:", request, initialLat, initialLng, directionsService);
    console.log("route map: ", map, mapProps);

    directionsService.route(request, (result, status) => {
      console.log("got directions result: ", result, status);
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
        directionsRenderer.setDirections(result); // Set directions to renderer
        directionsRenderer.setMap(map); // Render directions on the map
      } else {
        console.error('Error fetching directions:', status);
      }
    });
  };

  return (
    <div className='home-screen'>
      <MapComponent
        lat={initialLat}
        lng={initialLng}
        onMapLoad={onMapLoad}
        directions={directions}
      />

      <div className='bookmap-container'>
        <div className='bookmap-overlay'>
          <div className='bookheader'>
            <button
              className='back-button'
              onClick={() => navigate(-1)}
            >
              <BsArrowLeftShort className='headerback-button' />
            </button>
            <h1 className='booktext m-2'>Book Your Ride</h1>
          </div>
          <Destinations directions={directions} />
        </div>
      </div>
    </div>
  );
};

let riderData = [];
const Destinations = (props) => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const {directions} = props;
  const {
    sourceLat,
    sourceLng,
    sourceAddress,
    destinationLat,
    destinationLng,
    destinationAddress,
    pickupAddress,
  } = state;
  riderData = state;
  const handleContinue = () => {
    setIsLoading(true);

    const distanceText = directions.routes[0].legs[0].distance.text || "";
    const durationText = directions.routes[0].legs[0].duration.text || "";

    setTimeout(() => {
      setIsLoading(false);
      navigate("/selectvehicle", {
        state: {
          riderData,
          sourceLat,
          sourceLng,
          destinationLat,
          destinationLng,
          pickupAddress: pickupAddress,
          destinationAddress: destinationAddress,
          distanceText, durationText,
        },
      });
    }, 2000);
  };

  const continueRide = () => (
    <button
      onClick={handleContinue}
      className='buttonStyleFw'
    >
      Continue
    </button>
  );

  return (
    <div className='recent-destinations-container'>
      <div
        className='recent-destinations-bar'
        onClick={() => setOpen(!isOpen)}
      ></div>
      {isOpen && <div>
        {directions?.routes && <div>
          <strong>
            {directions.routes[0].legs[0].distance.text}
          </strong>
          &nbsp;({directions.routes[0].legs[0].duration.text})
        </div>}
        <ul className='recent-destinations'>
          {destinations.map((destination, index) => (
            <li
              key={index}
              className='booking-destination-item'
            >
              <div className='booking-destination-icon'>
                {destination.destinationType === "departure" ? (
                  <FaBullseye style={{ marginRight: "5px" }} />
                ) : destination.destinationType === "arrival" ? (
                  <FaMapMarkerAlt
                    style={{ marginRight: "10px", color: "#FF0000" }}
                  />
                ) : (
                  <FaMapMarkerAlt style={{ marginRight: "5px" }} />
                )}
              </div>

              <div className='destination-item-detail'>
                {/* <div className="destination-name" >{destination.address}</div> */}

                {destination.destinationType === "departure" ? (
                  <div className='destination-name'>{sourceAddress}</div>
                ) : destination.destinationType === "arrival" ? (
                  <div className='destination-name'>{destinationAddress}</div>
                ) : (
                  ""
                )}

                {/* <div className="destination-name" >{destination.name}</div> */}
                {/* <div className="destination-address">{destination.address}</div> */}
              </div>
            </li>
          ))}
        </ul>
        <div style={{ marginBottom: 40, marginTop: 30 }}>{continueRide()}</div>
      </div>}
      {isLoading && (
        <div className='loading-overlay'>
          <div className='loader'></div>
          <p> Calculating price...</p>
        </div>
      )}
    </div>
  );
};

export default BookRide;
