// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { getFunctions,httpsCallable } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHGY_YGr8iM_lanGse3ChnpX2RkksaILY",
  authDomain: "rajlo-dev.firebaseapp.com",
  databaseURL: "https://rajlo-dev-default-rtdb.firebaseio.com",
  projectId: "rajlo-dev",
  storageBucket: "rajlo-dev.appspot.com",
  messagingSenderId: "421254217589",
  appId: "1:421254217589:web:300e96f7ae1ef2d2b25d3c",
  measurementId: "G-2BPBV32LF0"
};

// let messageText;
// const functions = getFunctions();
// const addMessage = httpsCallable(functions, 'addMessage');
// addMessage({ text: messageText })
//   .then((result) => {
//     // Read result of the Cloud Function.
//     /** @type {any} */
//     const data = result.data;
//     const sanitizedMessage = data.text;
//   });

// const firebaseConfig = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: "https://rajlo-dev-default-rtdb.firebaseio.com",
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
//   measurementId: "G-2BPBV32LF0"
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Auth
export let auth = getAuth();

// Initialize Firestore

export const firestore = getFirestore(app);
// export const functions = getFunctions(app);

