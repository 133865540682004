import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MapComponent from "../../components/MapComponent";
import "./HomeScreen.css";
import { Modal, Button } from "react-bootstrap";
import {
  FaBars,
  FaSearch,
  FaStar,
  FaMapMarkerAlt,
  FaCarAlt,
} from "react-icons/fa";
import Sheet from "react-modal-sheet";
import { BsArrowLeftShort, BsFillRecord2Fill } from "react-icons/bs";
import { RiWallet3Fill } from "react-icons/ri";
import { IoMdHome, IoMdNotifications, IoMdMail } from "react-icons/io";
import { MdOutlineWork, MdOutlineLogout } from "react-icons/md";
import { GrRefresh } from "react-icons/gr";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { FiEdit3 } from "react-icons/fi";
import { FaCircleQuestion } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import Autocomplete from "react-google-autocomplete";
import ngeohash from "ngeohash";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { fetchUserData } from "../../services/userService";
import { Link } from 'react-router-dom';
import global from "../../global";

const recentDestinations = [];
const recent = [];

const HomeScreen = () => {
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [show, setShow] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [pickupAddress, setPickupAddress] = useState("");
  const [locationPermissionDenied, setLocationPermissionDenied] =
    useState(false);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [errors, setErrors] = useState({ pickup: false, destination: false });
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [locationPermission, setLocationPermission] = useState("prompt");
  const [listOfDrivers, setListOfDrivers] = useState([]);
  const [user, setUser] = useState({});
  const [isMounted, setIsMounted] = useState(true);
  const [showExplanationDialog, setShowExplanationDialog] = useState(false);

  const recheckLocationPermission = () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          console.log("Location permission state:", permissionStatus.state);
          setLocationPermission(permissionStatus.state); // Update locationPermission state
          // Handle permission status accordingly
          switch (permissionStatus.state) {
            case "granted":
              // Location access is granted
              getCurrentPosition();
              break;
            case "prompt":
              // Permission is pending (prompt may be shown)
              getCurrentPosition();

              break;
            case "denied":
              // Location access is denied
              setLocationPermissionDenied(true);
              setIsMapLoading(false);
              break;
          }
        })
        .catch((error) => {
          console.error("Error checking location permission:", error);
        });
    } else {
      console.error("Geolocation permissions not supported.");
    }
  };

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (isMounted) {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLocationPermissionDenied(false);

          // Store latitude and longitude in local storage
          localStorage.setItem(
            "currentLat",
            position.coords.latitude.toString()
          );
          localStorage.setItem(
            "currentLng",
            position.coords.longitude.toString()
          );
        }
      },
      (error) => {
        console.error("Error Code = " + error.code + " - " + error.message);
        setIsMapLoading(false); // Set to false when the map is loaded
        if (error.code !== 2) {
          recheckLocationPermission();
        }
        if (error.code === error.PERMISSION_DENIED) {
          setLocationPermissionDenied(true);
        }
      }
    );
  };

  //const center = [currentPosition.lat, currentPosition.lng];
  //const radiusInM = 8 * 10000;

  const getDriverLocation = async () => {
    //const bounds = geohashQueryBounds(center, radiusInM);
    //onsole.log("bounds---->", bounds);

    const q = query(
      collection(firestore, "location"),
      where('status', '==', 'Available'),
      orderBy("g.geohash"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const lat_lng = [];
      querySnapshot.forEach((doc) => {
        const driverGeohash = doc.get("g.geohash");
        if (driverGeohash) {
          const { latitude, longitude } = ngeohash.decode(driverGeohash);
          lat_lng.push([latitude, longitude]);
        }
      });
      console.log("setListOfDrivers driverGeohash: ", lat_lng);
      setListOfDrivers(lat_lng);
    });
    //return unsubscribe;
  };

  useEffect(() => {
    const checkRideStatus = async () => {
      if (!(user && user.id)) return;

      console.log("getting unfinished rides: ", user.id);

      const ridesQuery = query(
        collection(firestore, "Rides"),
        where("rider", "==", user.id),
        where("status", "in", ["driverEnroute", "passengerEnroute"])
      );

      const ridesSnapshot = await getDocs(ridesQuery);

      if (!ridesSnapshot.empty) {
        const rideDoc = ridesSnapshot.docs[0];
        const rideData = rideDoc.data();
        const riderData = {
          sourceLat: rideData.origin.latitude,
          sourceLng: rideData.origin.longitude,
          sourceAddress: rideData.origin.address,
          destinationLat: rideData.destination.latitude,
          destinationLng: rideData.destination.longitude,
          destinationAddress: rideData.destination.address,
        };

        if (rideData.status === "driverEnroute") {
          console.log("navigating to driverdetail: ", rideData);
          navigate("/driverdetail", {
            state: { ride: rideData, riderData, tripId: rideDoc.id },
          });
        } else if (rideData.status === "passengerEnroute") {
          console.log("navigating to endride: ", rideData);
          navigate("/endride", {
            state: { ride: rideData, riderData, tripId: rideDoc.id },
          });
        }
      }
    };

    checkRideStatus();
  }, [navigate, user]);

  useEffect(() => {
    let isMounted = true; // Flag to indicate whether the component is mounted

    fetchUserData().then((userData) => {
      setUser(userData);
    });

    // Check location permission status when the component mounts
    recheckLocationPermission();
    getDriverLocation();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  const handleMapLoad = () => {
    setIsMapLoading(false); // Set to false when the map is loaded
  };

  const handleCloseExplanationDialog = () => {
    setShowExplanationDialog(false);
  };

  return (
    <div className="home-screen">
      {currentPosition && (
        <MapComponent
          lat={currentPosition.lat}
          lng={currentPosition.lng}
          onMapLoad={handleMapLoad}
          listOfDrivers={listOfDrivers}
        />
      )}
      {isMapLoading && (
        <div className="loading-overlay">
          <div className="loader"></div>
          <p>Loading Your Current Location...</p>
        </div>
      )}
      <div className="map-container">
        <div className="map-overlay">
          {locationPermissionDenied && (
            <>
              <div className="noPermission">
                <span style={{ display: "block", maxWidth: 500 }}>
                  Location permissions are disabled. Please enable location
                  permissions in your browser settings.
                </span>
                <Button
                  onClick={() => setShowExplanationDialog(true)}
                  style={{ marginTop: "10px" }}
                >
                  How to Enable
                </Button>
              </div>
              <Modal
                show={showExplanationDialog}
                onHide={handleCloseExplanationDialog}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error: Re-enable Permissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h2 style={{ textAlign: "center", color: "red" }}>
                    Location Permission Denied
                  </h2>
                  <p>
                    To use the map feature, please enable location permissions
                    in your browser settings.
                  </p>
                  <p>
                    <strong>For Chrome:</strong> Click on the lock icon in the
                    address bar, then go to "Site settings" and set "Location"
                    to "Allow".
                  </p>
                  <p>
                    <strong>For Firefox:</strong> Click on the shield icon in
                    the address bar, then go to "Permissions" and set "Location"
                    to "Allow".
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <Button onClick={() => window.location.reload()}>
                      Reload Page
                    </Button>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseExplanationDialog}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
          <div className="menu-bar">
            <FaBars
              className="hamburger-menu"
              style={{ marginRight: "5px" }}
              onClick={handleShow}
            />
          </div>
          {/* <div className='current-location-bar'>
            <FaBars
              style={{ marginRight: "5px" }}
              onClick={handleShow}
            />

            <div className='current-location-text'>
              <FaMapMarkerAlt className='mapcurrent' />
              Current Location
            </div>
          </div> */}
          <RecentDestinations
            recentDestinations={recentDestinations}
            pickupAddress={pickupAddress}
            setPickupAddress={setPickupAddress}
            destinationAddress={destinationAddress}
            setDestinationAddress={setDestinationAddress}
            errors={errors}
            setErrors={setErrors}
            currentPosition={currentPosition}
          />{" "}
          <SideMenu show={show} handleClose={handleClose} user={user} />
        </div>
      </div>
    </div>
  );
};

const SidebarHeader = ({ user }) => {
  const navigate = useNavigate();
  const placeholderImage = "/assets/images/profile.jpg"; // Update this path to your actual placeholder image path

  const editbutton = () => {
    navigate("/editprofile");
  };

  return (
    <Card
      style={{
        backgroundColor: "red",
        borderRadius: "0px",
        height: 160,
        marginTop: 0
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ alignItems: "center", flex: "0.4", cursor: "pointer" }}>
          <div style={{ display: "flex", marginTop: 20 }}>
            <div style={{ flex: "1" }} onClick={editbutton}>
              <div className="circle-icon3 ">
                <FiEdit3 className="editcircle" />
              </div>
              <img
                src={user && user.image ? user.image : placeholderImage}
                className="user-image"
              />
            </div>
          </div>
        </div>
        <div className="maintextdiv">
          <span className="nametext">{user.name || ""}</span>
          {/* <span >Samantha Smith</span> */}
          <div className="emailtext">{user.email || ""}</div>
        </div>
      </div>
    </Card>
  )
}


const SideMenu = ({ show, handleClose, user }) => {
  const navigate = useNavigate();

  const homebutton = () => {
    navigate("/home");
  };
  const walletbutton = () => {
    navigate("/paymentmethod");
  };

  const notificationbutton = () => {
    navigate("/notification");
  };

  const myridebutton = () => {
    navigate("/myrides");
  };
  const contactbutton = () => {
    navigate("/contactus");
  };
  const logoutbutton = () => {
    navigate("/login");
  };
  const faqbutton = () => {
    navigate("/faqs");
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      style={{ width: "85%", maxWidth: 400 }}
    >
      <Offcanvas.Header style={{ padding: "0px", marginTop: "10px" }}>
        <div style={{ flex: 1 }}>
          <SidebarHeader user={user} />
          <div className="closeicondiv" onClick={handleClose}>
            <IoClose className="closeicon" />
          </div>

          <div className="listingContainer">

            <div className="listingdiv" onClick={homebutton}>
              <div className="circle-icon ">
                <IoMdHome className="homecircle" />
              </div>
              <div className="headingtext"> Home</div>
            </div>

            <div className="listingdiv" onClick={myridebutton}>
              <div className="circle-icon ">
                <FaCarAlt className="ridecircle" />
              </div>
              <div className="headingtext"> My Rides</div>
            </div>

            <div className="listingdiv" onClick={walletbutton}>
              <div className="circle-icon ">
                <RiWallet3Fill className="ridecircle" />
              </div>
              <div className="headingtext"> Payment</div>
            </div>

            <div className="listingdiv" onClick={notificationbutton}>
              <div className="circle-icon ">
                <IoMdNotifications className="homecircle" />
              </div>
              <div className="headingtext"> Notifications</div>
            </div>
            <div className="listingdiv" onClick={faqbutton}>
              <div className="circle-icon ">
                <FaCircleQuestion className="homecircle" />
              </div>
              <div className="headingtext"> FAQs</div>
            </div>
            <div className="listingdiv" onClick={contactbutton}>
              <div className="circle-icon ">
                <IoMdMail className="homecircle" />
              </div>
              <div className="headingtext"> Contact Us</div>
            </div>
            <div className="listingdiv" onClick={logoutbutton}>
              <div className="circle-icon ">
                <MdOutlineLogout className="homecircle" />
              </div>
              <div className="headingtext"> Logout</div>
            </div>
          </div>

        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body">
        <div className="footer-links">
          <div className="listingdiv">
            <Link to="/privacypolicy" className="footer-link">Privacy Policy</Link>
          </div>
          <div className="listingdiv">
            <Link to="/termsofuse" className="footer-link">Terms of Use</Link>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

let sourceLat = [];
let sourceLng = [];
let sourceAddress = [];
let destinationLat = [];
let destinationLng = [];
let destinationAddress = [];
let PlaceValue = [];
let DestinationValue = [];

const RecentDestinations = ({
  recentDestinations,
  pickupAddress,
  setPickupAddress,
  destinationAddress,
  setDestinationAddress,
  errors,
  setErrors,
  currentPosition,
}) => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  const setSheetOpen = (open) => {
    setOpen(open);
  };

  const getCurrentLocation = (place) => {
    PlaceValue = place[0];
    sourceLat = place.geometry.location.lat();
    sourceLng = place.geometry.location.lng();
    sourceAddress = place.formatted_address;
    console.log(sourceLat, sourceLng, sourceAddress);

    // navigate("/bookride",{ state: { LatV,LngV,address}} );
  };

  const getDestinationLocation = (place) => {
    DestinationValue = place[0];
    destinationLat = place.geometry.location.lat();
    destinationLng = place.geometry.location.lng();
    destinationAddress = place.formatted_address;
    console.log(destinationLat, destinationLng, destinationAddress);

    // navigate("/bookride",{ state: { LatV,LngV,address}} );
  };

  // Validation function
  const validateInputs = () => {
    const errorsFound = {};
    if (!pickupAddress) {
      errorsFound.pickup = true;
    }
    if (!destinationAddress) {
      errorsFound.destination = true;
    }
    console.log("Run Validation", errorsFound);

    setErrors(errorsFound);

    console.log("Errors Validation", errors);

    return Object.keys(errorsFound).length === 0; // Returns true if no errors found
  };

  const submitButton = () => {
    if (!validateInputs()) {
      // If validation fails, return early and do not proceed
      return;
    }

    navigate("/bookride", {
      state: {
        sourceLat,
        sourceLng,
        sourceAddress,
        destinationLat,
        destinationLng,
        destinationAddress,
        pickupAddress,
      },
    });
  };

  return (
    <div className="recent-destinations-container">
      <div
        className="recent-destinations-bar"
        // onClick={() => setOpen(true)}
      ></div>

      <div
        className="autocomplete-wrapper"
        style={{ position: "relative", marginBottom: "16px" }}
      >
        <FaMapMarkerAlt
          style={{
            position: "absolute",
            color: "#FF0000",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: "2",
          }}
        />
        <Autocomplete
          apiKey={global.googleMapsKey}
          onPlaceSelected={(place) => {
            if (!place || !place.formatted_address) {
              console.error(place);
              return; // Exit the function if place is not defined or doesn't have formatted_address
            }
            setPickupAddress(place.formatted_address); // Update pickupAddress state
            getCurrentLocation(place);
          }}
          options={{
            types: [],
            // location: currentPosition ?? undefined,
            // radius: 150000, // 150 kilometers
            // strictBounds: true,
          }}
          id="pickup-autocomplete"
          name="pickup"
          className={`location-autocomplete ${errors.pickup ? "error" : ""}`}
          placeholder="Pickup Location"
        />
      </div>

      {/* {current == true ? ( */}
      <div>
        <div className="autocomplete-wrapper" style={{ position: "relative" }}>
          <FaSearch
            style={{
              position: "absolute",
              color: "#FF0000",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: "2",
            }}
          />
          <Autocomplete
            apiKey={global.googleMapsKey}
            onPlaceSelected={(place) => {
              if (!place || !place.formatted_address) {
                console.error("Place or formatted_address is undefined.");
                return; // Exit the function if place is not defined or doesn't have formatted_address
              }
              setDestinationAddress(place.formatted_address); // Update pickupAddress state
              getDestinationLocation(place);
            }}
            options={{
              types: [],
              // location: currentPosition ?? undefined,
              // radius: 150000, // 150 kilometers
              // strictBounds: true,
            }}
            id="destination-autocomplete"
            name="destination"
            className={`location-autocomplete ${errors.destination ? "error" : ""
              }`}
            placeholder="Where to go?"
          />
        </div>
        <button onClick={submitButton} className="buttonStyleFw">
          Continue
        </button>
      </div>
      {/* ) : null} */}

      <ul className="recent-destinations">
        {recentDestinations.map((destination, index) => (
          <li key={index} className="destination-item">
            <div className="destination-item-favorite">
              <FaStar style={{ marginRight: "5px" }} />
            </div>
            <div className="destination-item-detail">
              <div className="destination-name">{destination.name}</div>
              <div className="destination-address">{destination.address}</div>
            </div>
          </li>
        ))}
      </ul>

      <RecentDestinationsSheet isOpen={isOpen} setSheetOpen={setSheetOpen} />
    </div>
  );
};

const RecentDestinationsSheet = ({ isOpen, setSheetOpen }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const mapsApiKey = "AIzaSyAkFxK3CMpP7OKZwTj05UknVH8S3G0HgYY";

  const [userLocation, setUserLocation] = useState(null);
  const [value, setValue] = useState(null);
  const [lat, setLat] = useState("");
  const handleContinue = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate("/bookride");
    }, 2000);
  };

  return (
    <Sheet isOpen={isOpen} onClose={() => setSheetOpen(false)}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content
          disableDrag={true}
          className={isOpen ? "recent-destinations-sheet" : ""}
        >
          <div className="home-screen-container">
            <div
              className="homeheader mb-4"
              onClick={() => setSheetOpen(false)}
            >
              <BsArrowLeftShort className="back-button" />
            </div>

            <div className="source-destination-section">
              <div>
                <div className="connection-line">
                  <BsFillRecord2Fill className="firsticon" />
                  <div className="dotted-line"></div>
                  <FaMapMarkerAlt
                    style={{ color: "#FF0000" }}
                    className="secondicon"
                  />
                </div>
              </div>

              <div>
                {/* <div className="icondiv mb-4">Current Location</div> */}
                {/* <AutoComplete></AutoComplete> */}

                {/* <Autocomplete
                  apiKey={mapsApiKey}
                  onPlaceSelected={(place) => {
                    console.log(place);
                  }}
                />
                <div className="hrline mb-3"></div> */}
                {/* <input ref={ref} />/ */}
                {/* <input type="text"  className="form-control" placeholder="Current Location" style={{borderColor:'white'}} onClick={getUserLocation}/> */}
                <div className="hrline mb-3"></div>

                <div className="progress-step__marker"></div>
                {/* <div className="current-location-text">Where to go?</div> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Where to go?"
                  style={{ borderColor: "white" }}
                />
              </div>
            </div>

            <div className="homeicon mt-5">
              <IoMdHome style={{ color: "#FF0000" }} className="secondicon" />{" "}
              Home
            </div>

            <div className="homeicon mt-4">
              <MdOutlineWork
                style={{ color: "#FF0000" }}
                className="secondicon"
              />{" "}
              Work
            </div>
            <div className="recenttext mt-5">Recent</div>
            <ul className="recent-destinations">
              {recent.map((destination, index) => (
                <li
                  key={index}
                  className="destination-item2"
                  onClick={handleContinue}
                >
                  <div
                    className="destination-item-favorite"
                    onClick={handleContinue}
                  >
                    <GrRefresh style={{ marginRight: "5px" }} />
                  </div>
                  <div className="destination-item-detail">
                    <div className="destination-name">{destination.name}</div>
                    <div className="destination-address">
                      {destination.address}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {isLoading && (
              <div className="loading-overlay">
                <div className="loader"></div>
                <p>Please wait...</p>
              </div>
            )}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default HomeScreen;
