import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import global from "../global"

export const MapComponent = (props) => {
  const {
    lat,
    lng,
    google,
    destination,
    directions,
    onMapLoad,
    listOfDrivers,
  } = props; // Destructuring props for clarity

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  return (
    <Map
      google={google}
      zoom={11}
      style={mapStyles}
      initialCenter={{ lat, lng }}
      disableDefaultUI={true}
      onReady={onMapLoad} // Call the onMapLoad prop when the map is ready
    >
      {!directions && <Marker
        icon={"http://maps.google.com/mapfiles/ms/micons/blue.png"}
        position={{ lat, lng }} />}

      {listOfDrivers &&
        listOfDrivers.map((driver, idx) => (
          <Marker
            key={idx}
            position={{ lat: driver[0], lng: driver[1] }}
            icon={"/assets/images/DriverIcon.png"}
          />
        ))}
      {destination && <Marker position={destination} />}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: global.googleMapsKey, // Replace with your Google Maps API Key
})(MapComponent);
