import React, { useState, useEffect} from "react";
import SwipeableViews from "react-swipeable-views";
import { FaArrowRight } from "react-icons/fa"; // Import FaArrowRight
import { useNavigate } from "react-router-dom"; // Import useNavigate

import "./OnboardingScreen.css";

const onboardingScreens = [
  {
    title: "Select Location",
    description: "Drive smarter knowing the best route and time to go",
    image: "/assets/images/onboarding/onboarding1.png",
  },
  {
    title: "Choose Your Ride",
    description: "Get real-time traffic information and avoid congested roads",
    image: "/assets/images/onboarding/onboarding2.png",
  },
  {
    title: "Enjoy Your Ride",
    description: "Connect with family from anywhere in the world",
    image: "/assets/images/onboarding/onboarding3.png",
  },
];

const OnboardingScreen = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);


  useEffect(() => {
    const userLogin = localStorage.getItem('userLogin');
    if (userLogin) {
      navigate('/home');
    }
  }, [navigate]);

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < onboardingScreens.length) {
      setActiveStep(nextStep);
    } else {
      navigate("/login"); // Navigate to '/login' on the last slide
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    navigate("/login"); // Navigate to '/login' when Skip is clicked
  };

  const maxSteps = onboardingScreens.length;

  return (
    <div className="onboarding-container">
      <SwipeableViews
        index={activeStep}
        className="swipeable-views-container"
        onChangeIndex={setActiveStep}
        enableMouseEvents
      >
        {onboardingScreens.map((screen, index) => (
          <div key={index} className="slide">
            <img
              src={screen.image}
              alt={screen.title}
              className="onboarding-image"
            />
            <h2 className="onboarding-title">{screen.title}</h2>
            <p className="onboarding-description">{screen.description}</p>
          </div>
        ))}
      </SwipeableViews>
      <div className="onboarding-controls">
        <button onClick={handleSkip} className="skip-button">
          Skip
        </button>

        <button onClick={handleNext} className="control-button next-button">
          <FaArrowRight style={{ color: "#FF0000" }} />
        </button>
      </div>
      <div className="onboarding-indicators">
        {onboardingScreens.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === activeStep ? "active" : ""}`}
            onClick={() => setActiveStep(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default OnboardingScreen;
