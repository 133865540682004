import React, { useState } from 'react';
import { useNavigate ,useParams,useLocation} from 'react-router-dom';
import { BsArrowLeftShort } from "react-icons/bs";
import { sendOtp } from "../../services/otpService";
import './RegisterScreen.css';

const RegisterScreen = () => {
    // const params = useParams();
    const location = useLocation()
    // Check if location.state is not null before destructuring
    const phone = location.state ? location.state.phoneNumber : null;
    const code = location.state ? location.state.countryCode : null;
    console.log("route.params in register", phone)
    // console.log("params--->",params)
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(phone || '');
    const [countryCode, setCountryCode] = useState(code || "+1");
    const [mainLoader, setMainLoader] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate();

    const countryCodes = ["+1", "+91"];

    const countryCodesDropdown = () => (
        <select
        value={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        className="countryCodeDropdown"
        >
        {countryCodes.map((code) => (
            <option key={code} value={code}>
            {code}
            </option>
        ))}
        </select>
    );


    const onContinue = async () => {
        setMainLoader(true);

        // Validation
        if (!name || name.length < 2) {
            setMainLoader(false);
            setAlertMessage("Please enter your name.");
            return;
        }

        if (!phoneNumber) {
            setMainLoader(false);
            setAlertMessage("Please enter a phone number.");
            return;
        }

        if (phoneNumber.length !== 10) {
            setMainLoader(false);
            setAlertMessage("Please enter 10-digit phone number.");
            return;
        }

        // Send OTP
        try {
            const fullPhoneNumber = `${countryCode}${phoneNumber}`; // Concatenate country code and phone number
            const confirmResult = await sendOtp(fullPhoneNumber);
            console.log("onContinue confirmation", confirmResult);

            setMainLoader(false);
            navigate("/verify", {state:{phoneNumber:fullPhoneNumber,registerName:name}});
        } catch (error) {
            console.log("onContinue: ", error);
            setMainLoader(false);
            setAlertMessage("Error sending phone verification. Please contact administrator.");
        }
    };

    const continueButton = () => (
        <button
            onClick={onContinue}
            className="continue-button"
            id="recaptcha-btn"
            style={{ textDecoration: "none" }}
            disabled={mainLoader}
        >
        {mainLoader ? (
            <div className="inline-loader">
            <div className="loader-btn"></div>
            </div>
        ) : (
            "Continue"
        )}
        </button>
    );

    return (
        <div className="wrapper">
            <div className="safe-area">
                <div className="registercontent">
                    <div className="registerheader mb-4">
                        <button className="registerback-button" onClick={() => navigate(-1)}>
                            <BsArrowLeftShort  className="registerback-button" />
                        </button>
                        <h1 className="registernavigation-button-label m-2">Register</h1>
                    </div>
                    <div className="form-group">
                        {alertMessage && <div className="alertMessageStyle">{alertMessage}</div>}
                        <div style={{padding: '15px'}}>
                            {fullNameInfo()}
                            {phoneNumberInfo()}
                        </div>
                    </div>
                </div>
                <div className="registerbuttondiv">
                {continueButton()}
                </div>
            
            </div>
        </div>
    );

    // function continueButton() {
    //     return (
    //         <button
    //             className="continue-button"
    //             onClick={() => navigate('/verify')}
    //         >
    //             Continue
    //         </button>
    //     );
    // }

    function phoneNumberInfo() {
        return (
            <div className="input-group">
                <label className="labeltext">Phone Number</label>

                <div className="nameInputContainer">
                    {countryCodesDropdown()}
                    <input
                        style={{paddingLeft: '7px'}}
                        type="tel"
                        // value={location.state=="null"?phoneNumber:phoneNumber}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter your phone number..."
                        className="nameInputStyle"
                    />
                    </div>
            </div>
        );
    }

    function fullNameInfo() {
        return (
            <div className="input-group">
                <div className="label-container">
                    <label className="labeltext">Full Name</label>
                </div> 
                  <div className="nameInputContainer"> 
                    <input
                    style={{paddingLeft: '7px'}}
                        type="text"
                        value={name}
                        onChange={(e) => (setName(e.target.value), setAlertMessage(''))}
                        placeholder="Enter name..."
                        className="nameInputStyle"
                    />
                    </div>
            </div>
        );
    }
}

export default RegisterScreen;
